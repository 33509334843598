import { USER_SET } from "../actionTypes/index";
import { FIRST_TIME } from "../actionTypes/index";
import { SET_CART_COUNT } from "../actionTypes/index";

export function setUser(user) {
  return {
    type: USER_SET,
    payload: user,
  };
}

export function setFirstTime(user) {
  return {
    type: FIRST_TIME,
    payload: user,
  };
}

export function setCartCount(user) {
  return {
    type: SET_CART_COUNT,
    payload: user,
  };
}
