import React, { Component } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";

import {
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import moment from "moment";

import ReactAudioPlayer from "react-audio-player";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.min.css";

import Book from "../Blocks/Book";
import Carousell from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

// import PDFViewer from "pdf-viewer-reactjs";

import { Page, Document } from "react-pdf";

// import { Document } from "react-pdf/dist/esm/entry.parcel";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class BookDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1200, min: 768 },
          items: 3,
        },
        mobile: {
          breakpoint: { max: 768, min: 576 },
          items: 2,
        },
        Sm_mobile: {
          breakpoint: { max: 576, min: 0 },
          items: 1,
        },
      },
      bookContetnsResponsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 8,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 6,
        },
        tablet: {
          breakpoint: { max: 1200, min: 768 },
          items: 5,
        },
        mobile: {
          breakpoint: { max: 768, min: 576 },
          items: 4,
        },
        Sm_mobile: {
          breakpoint: { max: 576, min: 0 },
          items: 4,
        },
      },
      BookDetails: {},
      BookDetailsLoader: true,
      randomBooks: [],
      randomBooksLoader: true,

      photoIndex: 0,
      isOpen: false,
      BookContect: [],
      Comments: [],
      userComment: "",
      numPagess: 0,
      pageNumber: 1,
    };
  }
  componentWillMount() {
    this.BookDetails();
    this.getRandomBooks();
  }

  /**  getRandomBooks Page */
  getRandomBooks() {
    fetch(Global.api_link + "getRandomBooks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          randomBooks: result.random,
          randomBooksLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  getRandomBooks Page End */

  /**  BookDetails api */
  BookDetails() {
    fetch(Global.api_link + "getBooksDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        //"Content-Type": "application/json",
        // Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        book_id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          BookDetails: result.BookInfo,
          BookDetailsLoader: false,
          isFavoured: result.isFavoured,
          have_enough_balance: result.have_enough_balance,
          is_bought: result.is_bought,
          is_Have_Exam_Before: result.is_Have_Exam_Before,
          isRated: result.isRated,
          BookContect: result.BookContect,
          Comments: result.Comments,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  BookDetails api End */

  onDocumentLoadSuccess({ numPages }) {
    this.setState({
      numPagess: numPages,
    });
  }

  render() {
    return (
      <div className="bookDetails">
        {/* book details section start  */}
        <Container>
          {this.state.BookDetailsLoader ? (
            <div>
              <Skeleton height={400} />
            </div>
          ) : (
            <Row>
              {/* left side (img book in stage ) start  */}
              <Col md={5}>
                <div className="bookStageImg">
                  {/* book img start  */}

                  <img
                    src={Global.image_link + this.state.BookDetails.cover_image}
                    alt="h"
                  />
                  {/* book img end  */}
                  {/* gold book label start  */}
                  {this.state.BookDetails.is_golden_book == 0 ? null : (
                    <img
                      src="/img/gold-book.png"
                      alt="h"
                      className="goldBook"
                    />
                  )}
                  {/* gold book label end  */}
                  {/* <img src="/img/book-stand.png" alt="h" className="" /> */}
                </div>
                <div className="bookRate">
                  <span> قيم كتابنا</span>
                  <ul>
                    <li
                      onClick={() => {
                        if (this.props.user.UID == 69) {
                          window.location.href = "/login";
                        } else {
                          if (this.state.isRated == 0) {
                            this.setState(
                              {
                                rateCount: 1,
                              },
                              () => {
                                this.BookRate();
                              }
                            );
                          }
                        }
                      }}
                    >
                      <i
                        className={
                          (
                            this.state.isRated < 1
                              ? this.state.rateCount >= 1
                              : this.state.isRated >= 1
                          )
                            ? "fas fa-star"
                            : "far fa-star"
                        }
                      ></i>
                    </li>
                    <li
                      onClick={() => {
                        if (this.props.user.UID == 69) {
                          window.location.href = "/login";
                        } else {
                          if (this.state.isRated == 0) {
                            this.setState(
                              {
                                rateCount: 2,
                              },
                              () => {
                                this.BookRate();
                              }
                            );
                          }
                        }
                      }}
                    >
                      <i
                        className={
                          (
                            this.state.isRated < 2
                              ? this.state.rateCount >= 2
                              : this.state.isRated >= 2
                          )
                            ? "fas fa-star"
                            : "far fa-star"
                        }
                      ></i>
                    </li>
                    <li
                      onClick={() => {
                        if (this.props.user.UID == 69) {
                          window.location.href = "/login";
                        } else {
                          if (this.state.isRated == 0) {
                            this.setState(
                              {
                                rateCount: 3,
                              },
                              () => {
                                this.BookRate();
                              }
                            );
                          }
                        }
                      }}
                    >
                      <i
                        className={
                          (
                            this.state.isRated < 3
                              ? this.state.rateCount >= 3
                              : this.state.isRated >= 3
                          )
                            ? "fas fa-star"
                            : "far fa-star"
                        }
                      ></i>
                    </li>
                    <li
                      onClick={() => {
                        if (this.props.user.UID == 69) {
                          window.location.href = "/login";
                        } else {
                          if (this.state.isRated == 0) {
                            this.setState(
                              {
                                rateCount: 4,
                              },
                              () => {
                                this.BookRate();
                              }
                            );
                          }
                        }
                      }}
                    >
                      <i
                        className={
                          (
                            this.state.isRated < 4
                              ? this.state.rateCount >= 4
                              : this.state.isRated >= 4
                          )
                            ? "fas fa-star"
                            : "far fa-star"
                        }
                      ></i>
                    </li>
                    <li
                      onClick={() => {
                        if (this.props.user.UID == 69) {
                          window.location.href = "/login";
                        } else {
                          if (this.state.isRated == 0) {
                            this.setState(
                              {
                                rateCount: 5,
                              },
                              () => {
                                this.BookRate();
                              }
                            );
                          }
                        }
                      }}
                    >
                      <i
                        className={
                          (
                            this.state.isRated < 5
                              ? this.state.rateCount >= 5
                              : this.state.isRated >= 5
                          )
                            ? "fas fa-star"
                            : "far fa-star"
                        }
                      ></i>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* left side (img book in stage ) end  */}

              {/* right side (title && state && desc) start  */}

              <Col md={7}>
                <div className="mainBookDetails">
                  {/* name of book and auth start  */}
                  <div className="name_auth_book">
                    <h5>{this.state.BookDetails.book_name}</h5>
                    <p>{this.state.BookDetails.author_name}</p>
                    <span>
                      {this.state.BookDetails.price == 0 ||
                      this.state.BookDetails.price == null
                        ? "مجاني"
                        : this.state.BookDetails.price + " نقطة "}{" "}
                    </span>
                  </div>
                  {/* name of book and auth end  */}

                  {/* status of book and rate start  */}
                  <div className="bookStatus">
                    <div className="viewer_rate">
                      <div>
                        <img src="/img/witness.png" alt="l" />
                        <span>{this.state.BookDetails.visits}</span>
                      </div>
                      <div>
                        <img src="/img/startFill.png" alt="l" />
                        <span>{this.state.BookDetails.rate}</span>
                      </div>
                    </div>
                    {this.state.BookDetails.is_golden_book == 0 ? null : (
                      <p>ذهبي</p>
                    )}
                  </div>
                  {/* status of book and rate end  */}
                </div>

                {/* book details buttons start  */}
                <div className="bookDeatisl_buttons">
                  <div className="right_side">
                    {/* read book button start  */}
                    {this.state.is_bought == 1 ||
                    this.state.BookDetails.price < 1 ? (
                      this.props.user.UID == 69 ? (
                        <a href="/login" target="_blank">
                          اقرا الكتاب
                        </a>
                      ) : this.state.BookDetails.type_id == 1 ? (
                        // audio section start
                        <ReactAudioPlayer
                          // src={this.state.BookDetails.book_link}
                          controlsList="nodownload"
                          src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                          // autoPlay

                          controls
                        />
                      ) : // audio section end

                      this.state.BookDetails.type_id == 2 ? (
                        <>
                          <a
                            onClick={() => {
                              this.setState({
                                isOpen: true,
                              });
                            }}
                          >
                            {" "}
                            مشاهدة الكتاب{" "}
                          </a>

                          {/*##############  modal video section start  ########### */}
                          <ModalVideo
                            channel={
                              this.state.BookDetails.youtube_link != ""
                                ? "youtube"
                                : "custom"
                            }
                            autoplayp
                            isOpen={this.state.isOpen}
                            url={this.state.BookDetails.book_link}
                            onClose={() => {
                              this.setState({ isOpen: false });
                            }}
                          />
                          {/*##############  modal video section end    ########### */}
                        </>
                      ) : this.state.BookDetails.type_id == 3 ? (
                        <>
                          {/* // book image section start */}

                          <a
                            onClick={() => this.setState({ imageOpen: true })}
                            // href={
                            //   Global.image_link + this.state.BookDetails.book_link
                            // }
                            // target="_blank"
                          >
                            فتح الصورة
                          </a>

                          {this.state.imageOpen && (
                            <Lightbox
                              mainSrc={
                                Global.image_link +
                                this.state.BookDetails.book_link
                              }
                              // nextSrc={images[(photoIndex + 1) % images.length]}
                              // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                              onCloseRequest={() =>
                                this.setState({ imageOpen: false })
                              }
                              // onMovePrevRequest={() =>
                              //   this.setState({
                              //     photoIndex: (photoIndex + images.length - 1) % images.length,
                              //   })
                              // }
                              // onMoveNextRequest={() =>
                              //   this.setState({
                              //     photoIndex: (photoIndex + 1) % images.length,
                              //   })
                              // }
                            />
                          )}
                        </>
                      ) : // book image section end

                      this.state.BookDetails.type_id == 4 || this.state.BookDetails.type_id == 11 ? (
                        // book pdf section start

                        <a
                          // href={
                          //   Global.image_link + this.state.BookDetails.book_link
                          // }
                          onClick={() => {
                            this.setState({
                              pdfShow: !this.state.pdfShow,
                            });
                          }}
                          href={!this.state.pdfShow ? null : "#section"}
                        >
                          إقرأ الكتاب pdf
                        </a>
                      ) : // book pdf section start

                      this.state.BookDetails.type_id == 5 ? (
                        <>
                          {/* Enter your email modal start */}

                          <Modal
                            show={this.state.sendImageModal}
                            onHide={() => {
                              this.setState({
                                sendImageModal: false,
                              });
                            }}
                            className="enterEmailModal"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>طلب الصورة</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <h4>أدخل بريدك الالكتروني</h4>
                              <input
                                type="text"
                                placeholder={this.props.i18n.email}
                                onChange={(e) => {
                                  this.setState({
                                    EnterYourEmail: e.target.value,
                                  });
                                }}
                              />
                            </Modal.Body>
                            <Modal.Footer>
                              <div
                                className="sendEmail"
                                onClick={() => {
                                  this.sendPhotoImageToEmail();
                                }}
                              >
                                ارسال
                              </div>
                            </Modal.Footer>
                          </Modal>
                          {/* Enter your email modal end  */}
                          <a
                            onClick={() => {
                              this.setState({
                                sendImageModal: true,
                              });
                            }}
                          >
                            طلب الصورة
                          </a>
                        </>
                      ) : this.state.BookDetails.type_id == 6 ? (
                        <a
                          href={this.state.BookDetails.youtube_link}
                          target="_blank"
                        >
                          الذهاب الى الموقع
                        </a>
                      ) : null
                    ) : (
                      <a
                        onClick={() => {
                          if (this.props.user.UID == 69) {
                            window.location.href = "/login";
                          } else {
                            if (this.state.have_enough_balance == 1) {
                              this.AddOrder();
                            } else {
                              swal({
                                title: this.props.i18n.sorry,
                                text: "ليس لديك رصيد كافي",
                                icon: "warning",
                                buttons: {
                                  cancel: this.props.i18n.cancel,
                                  catch: {
                                    text: this.props.i18n.goToStore,
                                    value: "catch",
                                  },
                                },
                                className: "haveEnoughBalance",
                              }).then((go) => {
                                if (go) {
                                  window.location.href =
                                    "/profile/BalanceStore";
                                }
                              });
                            }
                          }
                        }}
                      >
                        شراء
                        <img src="/img/book.png" alt="k" />
                      </a>
                    )}

                    {/* read book button end  */}
                    {/* start test button start  */}

                    {this.state.BookDetails.is_golden_book == 0 ? null : this
                        .state.is_Have_Exam_Before == 0 ? (
                      <a href={"/TestPage/" + this.state.BookDetails.id}>
                        ابدأ الإختبار
                      </a>
                    ) : null}
                  </div>
                  {/* start test button end  */}

                  <div className="left_side">
                    {/* share button start  */}
                    <span
                      onClick={() => {
                        this.setState({
                          showShears: true,
                        });
                      }}
                    >
                      <img src="/img/share.png" alt="l" />
                    </span>
                    {/* share button end  */}
                    {/* add to fav button start  */}

                    <span
                      // className={this.state.isFavoured == 0 ? "" : "isFavBook"}
                      onClick={() => {
                        if (this.props.user.UID == 69) {
                          window.location.href = "/login";
                        } else {
                          this.AddRemoveFav();
                        }
                      }}
                    >
                      {this.state.isFavoured == 0 ? (
                        <img src="/img/favourite.png" alt="l" />
                      ) : (
                        <img src="/img/ff.png" alt="l" />
                      )}
                    </span>
                    {/* add to fav button end  */}
                  </div>
                </div>
                {/* book details buttons end  */}

                {/* book description start  */}
                <div className="bookDesc">
                  {this.state.BookDetails.description.length < 1 ? null : (
                    <h6>عن الكتاب</h6>
                  )}
                  <p
                    style={
                      this.state.showMore
                        ? { WebkitLineClamp: "inherit" }
                        : null
                    }
                  >
                    {this.MyComponent(this.state.BookDetails.description)}
                  </p>
                  {this.state.BookDetails.description.length > 425 ? (
                    <span
                      onClick={() => {
                        this.setState({
                          showMore: !this.state.showMore,
                        });
                      }}
                    >
                      {this.state.showMore ? "عرض أقل" : "عرض المزيد"}
                    </span>
                  ) : null}
                  <h6>محتويات الكتاب</h6>
                  {this.state.BookDetailsLoader ? null : (
                    <Carousell
                      responsive={this.state.bookContetnsResponsive}
                      showDots={true}
                      infinite={true}
                      showDots={false}
                      className="bookContents"
                      // className={this.state.categories.length < 12 ? "rtl" : ""}
                    >
                      {this.state.BookContect.map((item, index) => {
                        return (
                          <img
                            className="bookContentsImage"
                            src={Global.image_link + item.image}
                            alt="k"
                            onClick={() => {
                              this.setState({
                                bookContents: true,
                                photoIndex: index,
                              });
                            }}
                            key={index}
                          />
                        );
                      })}
                    </Carousell>
                  )}
                  {this.state.bookContents && (
                    <Lightbox
                      mainSrc={
                        Global.image_link +
                        this.state.BookContect[this.state.photoIndex].image
                      }
                      nextSrc={
                        Global.image_link +
                        this.state.BookContect[
                          (this.state.photoIndex + 1) %
                            this.state.BookContect.length
                        ].image
                      }
                      prevSrc={
                        Global.image_link +
                        this.state.BookContect[
                          (this.state.photoIndex +
                            this.state.BookContect.length -
                            1) %
                            this.state.BookContect.length
                        ].image
                      }
                      onCloseRequest={() =>
                        this.setState({ bookContents: false })
                      }
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex:
                            (this.state.photoIndex +
                              this.state.BookContect.length -
                              1) %
                            this.state.BookContect.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex:
                            (this.state.photoIndex + 1) %
                            this.state.BookContect.length,
                        })
                      }
                    />
                  )}
                </div>

                {/* book description end  */}
              </Col>
              {/* right side (title && state && desc) end  */}
            </Row>
          )}
        </Container>

        <Container>
          <div
            id="section"
            className={
              this.state.pdfShow ? "pdfSection_show pdfSection" : "pdfSection"
            }
          >
            {this.state.BookDetailsLoader ? null : (
              <>
                <Document
                  // file="/img/test.pdf"
                  // file="http://www.africau.edu/images/default/sample.pdf"
                  file={Global.image_link + this.state.BookDetails.book_link}
                  // onLoadSuccess={this.onDocumentLoadSuccess}
                  onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
                  onLoadError={() => {
                    console.log("aa2");
                  }}
                  onSourceError={() => {
                    console.log("aa3");
                  }}
                >
                  <Page pageNumber={this.state.pageNumber} />
                </Document>
                <div className="pdfPageNum">
                  <button
                    onClick={() => {
                      if (this.state.pageNumber > 1) {
                        this.setState({
                          pageNumber: this.state.pageNumber - 1,
                        });
                      }
                    }}
                  >
                    ‹
                  </button>
                  <span>
                    {this.state.pageNumber} of {this.state.numPagess}
                  </span>
                  <button
                    onClick={() => {
                      if (this.state.pageNumber < this.state.numPagess) {
                        this.setState({
                          pageNumber: this.state.pageNumber + 1,
                        });
                      }
                    }}
                  >
                    ›
                  </button>
                </div>
              </>
              // <PDFViewer
              //   document={{
              //     // url: Global.image_link + this.state.BookDetails.book_link,
              //     url: "/img/test.pdf",
              //     // url: "https://arxiv.org/pdf/quant-ph/0410100.pdf",

              //     // url: "https://arxiv.org/pdf/quant-ph/0410100.pdf",
              //   }}
              //   scale={window.innerWidth <= "425" ? 0.4 : 0.8}
              //   hideZoom={true}
              //   hideRotation={true}
              // />
            )}
          </div>
        </Container>
        {/* book details section end  */}

        {/* reviews of book section start  */}
        {this.props.user.UID == 69 ? null : (
          <div className="bookReviews">
            <Container>
              <Row>
                {/* add Review section start  */}
                <Col md={4}>
                  <div className="addReview">
                    <h5>كيف هي تجربتك</h5>
                    <p>ستساعدنا تجربتك في إجراء تحسينات</p>
                    <textarea
                      placeholder="اكتب رأيك"
                      onChange={(e) => {
                        this.setState({
                          userComment: e.target.value,
                        });
                      }}
                      value={this.state.userComment}
                    />
                    <span
                      onClick={() => {
                        if (this.state.userComment != "") {
                          this.AddBookComment();
                        }
                      }}
                    >
                      تعليق
                    </span>
                  </div>
                </Col>
                {/* add Review section end  */}

                <Col md={8}>
                  <div className="MemberReviews ">
                    <h5>تعليقات الاعضاء</h5>
                    {/* <div className="allReviews"> */}
                    <ul>
                      {this.state.BookDetailsLoader ? (
                        <span className="no_reviews">تحميل ...</span>
                      ) : this.state.Comments.length < 1 ? (
                        <span className="no_reviews">لا يوجد تعليقات</span>
                      ) : (
                        this.state.Comments.map((item, index) => {
                          {
                            /* single review start  */
                          }
                          return (
                            <li key={index}>
                              <div className="name_img">
                                {/* user image start  */}
                                <img
                                  src={
                                    item.image != null
                                      ? Global.image_link + item.image
                                      : "/img/userr.png"
                                  }
                                  alt="k"
                                />
                                {/* user image end  */}

                                <div className="name_review">
                                  {/* user name start  */}
                                  <p>{item.name}</p>
                                  {/* user name end  */}

                                  {/* the review start  */}
                                  <span>{item.comment}</span>
                                  {/* the review end  */}
                                </div>
                              </div>
                              {/* date of review start  */}
                              <span className="dateReview">
                                {moment(item.date_time).format("l")}
                              </span>
                              {/* date of review end  */}
                            </li>
                          );
                          {
                            /* single review end  */
                          }
                        })
                      )}
                    </ul>
                    {/* </div> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        {/* reviews of book section end  */}

        {/*## books maybe you like  start  */}

        {this.state.randomBooksLoader ? (
          <Container>
            <Skeleton height={300} />
          </Container>
        ) : (
          <div>
            <div className="latestVersion">
              <div className="carousel_title">
                <Container>
                  <div className="title_subCate">
                    <h5>كتب قد تنال إعجابك</h5>
                    {/* <div
                      onClick={() => {
                        this.setState({
                          last_headerTab_selected: 1,
                          showSub_categories: false,
                          showMain_categories: false,
                          showLastLevel: true,
                        });
                      }}
                    >
                      <span>المزيد</span>
                      <img src="/img/left.png" alt="k" />
                    </div> */}
                  </div>
                </Container>
              </div>
              <Container>
                <Carousell
                  responsive={this.state.responsive}
                  showDots={true}
                  infinite={true}
                  showDots={false}
                  // className={this.state.categories.length < 12 ? "rtl" : ""}
                >
                  {this.state.randomBooks.map((item, index) => {
                    return <Book bookData={item} key={index} />;
                  })}
                </Carousell>
              </Container>
            </div>
          </div>
        )}

        {/*## books maybe you like  end  */}

        {/* modal shear  start */}
        <Modal
          className="shareModal"
          show={this.state.showShears}
          onHide={() => this.setState({ showShears: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.i18n.ShareWithFriends}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ShareRow">
              {/* <h5>مشاركة </h5> */}
              <ul>
                <a>
                  <li>
                    <FacebookShareButton
                      url={
                        Global.siteLink +
                        "/bookDetails/" +
                        this.props.match.params.id
                      }
                      separator=":: "
                      // title={this.state.Details.title}
                      quote={"Look At This Amazing Book From Noon"}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </li>
                </a>
                <a>
                  <li>
                    <WhatsappShareButton
                      url={
                        Global.siteLink +
                        "/bookDetails/" +
                        this.props.match.params.id
                      }
                      separator=":: "
                      // title={this.state.Details.title}
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </li>
                </a>
                <a>
                  <li>
                    <EmailShareButton
                      url={
                        Global.siteLink +
                        "/bookDetails/" +
                        this.props.match.params.id
                      }
                      // subject={this.state.Details.title}
                      body="I want you to see this book from Noon "
                      separator=":: "
                      // title={this.state.Details.title}
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </li>
                </a>
                <a title="Copy Link">
                  <li
                    onClick={() =>
                      this.copyPageUrl(
                        Global.siteLink +
                          "/bookDetails/" +
                          this.props.match.params.id
                      )
                    }
                  >
                    <img src="/img/copy.png"></img>
                  </li>
                </a>
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        {/* modal shear  end */}
      </div>
    );
  }

  /**  BookRate api */
  BookRate() {
    fetch(Global.api_link + "BookRate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        book_id: this.props.match.params.id,
        rate: this.state.rateCount,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.BookDetails();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  BookRate api End */

  /**  Book Add Remove Favorite api */
  AddRemoveFav() {
    fetch(Global.api_link + "BookAddRemoveFavorite", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        book_id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.BookDetails();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  Book Add Remove Favorite api End */

  /**  Add Order api */
  AddOrder() {
    fetch(Global.api_link + "AddOrder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        book_id: this.props.match.params.id,
        points: this.state.BookDetails.price,
        txn_id: parseInt(Date.now()),
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        swal({
          title: this.props.i18n.done,
          text: "تم شراء الكتاب",
          icon: "success",
          button: {
            text: this.props.i18n.ok,
          },
        }).then(() => {
          this.BookDetails();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  Add Order api End */

  // Add Book Comment api start
  AddBookComment() {
    fetch(Global.api_link + "AddComments", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: 'Bearer ' + global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        book_id: this.props.match.params.id,
        comment: this.state.userComment,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        swal({
          title: this.props.i18n.done,
          text: "تم ارسال التعليق",
          icon: "success",
          button: {
            text: this.props.i18n.ok,
          },
        }).then(() => {
          this.setState({
            userComment: "",
          });
          this.BookDetails();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  // Add Book Comment api start

  // send Photo Image To Email api start
  sendPhotoImageToEmail() {
    fetch(Global.api_link + "sendPhotoImageToEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: 'Bearer ' + global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        book_id: this.props.match.params.id,
        email: this.state.EnterYourEmail,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        swal({
          title: this.props.i18n.done,
          text: "تم ارسال الصورة",
          icon: "success",
          button: {
            text: this.props.i18n.ok,
          },
        }).then(() => {
          this.setState({
            sendImageModal: false,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  // send Photo Image To Email api start

  // copy link url
  async copyPageUrl(url) {
    try {
      await navigator.clipboard.writeText(url);
      console.log("Page URL copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }
  // copy link url

  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }
  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookDetails);
