import React, { Component } from "react";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {}

  render() {
    return (
      <div>
        {this.props.isFavPage ? (
          <img
            src="/img/Untitled-1.png"
            alt="l"
            className="fav_label"
            onClick={() => {
              if (this.props.user.UID == 69) {
                window.location.href = "/login";
              } else {
                this.AddRemoveFav();
              }
            }}
          />
        ) : null}

        <a
          href={"/bookDetails/" + this.props.bookData.id}
          className={
            this.props.bookData.is_golden_book == 0
              ? "single_book "
              : "single_book goldBook"
          }
        >
          <h4>{this.props.bookData.book_name}</h4>
          <p>{this.props.bookData.author_name}</p>
          <div className="book_img">
            <img
              src={
                this.props.bookData.cover_image == null
                  ? "/img/no_book.jpg"
                  : Global.image_link + this.props.bookData.cover_image
              }
              alt="l"
            />
            {this.props.bookData.price == 0 ||
            this.props.bookData.price == null ? (
              <p>
                {this.props.bookData.is_golden_book == 0
                  ? this.props.i18n.Free
                  : this.props.i18n.goldBook}
              </p>
            ) : (
              <p className="notFree">
                {parseInt(this.props.bookData.price) + " "}
                نقطة
              </p>
            )}
          </div>
        </a>
      </div>
    );
  }

  /**  Book Add Remove Favorite api */
  AddRemoveFav() {
    fetch(Global.api_link + "BookAddRemoveFavorite", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        book_id: this.props.bookData.id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.props.callBackFun();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  Book Add Remove Favorite api End */
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Book);
