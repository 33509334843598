import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Book from "../Blocks/Book";
import Skeleton from "react-loading-skeleton";

class HisFavLib extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTab_selected:
        this.props.match.params.key == "MyLibrary"
          ? 5
          : this.props.match.params.key == "Favorite"
          ? 7
          : 8,
      screenContans: [],
      screenContansLoader: true,
    };
  }

  componentWillMount() {
    this.getScreensContent();
  }

  /**  getScreensContent Page */
  getScreensContent() {
    fetch(Global.api_link + "getScreensContent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        screen_id: this.state.headerTab_selected,
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          screenContans: result.Content,
          screenContansLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  getScreensContent Page End */

  render() {
    return (
      <div className="hisFavLib_Page">
        {/* header tabs section start  */}
        <div className="headerTabs">
          <Container>
            <ul>
              <li
                onClick={() => {
                  this.setState(
                    {
                      headerTab_selected: 5,
                      screenContansLoader: true,
                    },
                    () => {
                      window.location.href = "/favorities/MyLibrary";
                      this.getScreensContent();
                    }
                  );
                }}
                className={
                  this.state.headerTab_selected == 5 ? "bg_PrimaryColor" : ""
                }
              >
                {this.props.i18n.MyLibrary}
              </li>
              <li
                onClick={() => {
                  this.setState(
                    {
                      headerTab_selected: 7,
                      screenContansLoader: true,
                    },
                    () => {
                      window.location.href = "/favorities/Favorite";

                      this.getScreensContent();
                    }
                  );
                }}
                className={this.state.headerTab_selected == 7 ? "bg_Green" : ""}
              >
                {this.props.i18n.Favorite}
              </li>
              <li
                onClick={() => {
                  this.setState(
                    {
                      headerTab_selected: 8,
                      screenContansLoader: true,
                    },
                    () => {
                      window.location.href = "/favorities/history";

                      this.getScreensContent();
                    }
                  );
                }}
                className={this.state.headerTab_selected == 8 ? "bg_Gray" : ""}
              >
                {this.props.i18n.history}
              </li>
            </ul>
          </Container>
        </div>
        {/* header tabs section end  */}

        {/* // my library books starta */}
        <div className="books_tabs">
          <Container>
            <Row>
              {this.state.screenContansLoader ? (
                <>
                  <Col sm={6} md={4} lg={3}>
                    <Skeleton height={250} />
                  </Col>

                  <Col sm={6} md={4} lg={3}>
                    <Skeleton height={250} />
                  </Col>

                  <Col sm={6} md={4} lg={3}>
                    <Skeleton height={250} />
                  </Col>
                </>
              ) : this.state.screenContans.length < 1 ? (
                <div className="noResult">
                  <img src="/img/not_found.png" alt="l" />
                  <h3>لا يوجد كتب</h3>
                </div>
              ) : (
                <>
                  {this.state.screenContans.map((item, index) => {
                    return (
                      <Col sm={6} md={4} lg={3}>
                        {/* single book card start  */}
                        <Book
                          bookData={item}
                          key={index}
                          isFavPage={
                            this.state.headerTab_selected == 7 ? true : false
                          }
                          callBackFun={() => this.getScreensContent()}
                        />
                        {/* single book card end  */}
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HisFavLib);
