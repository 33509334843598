import React, { Component } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";

import Global from "../Global.json";
import moment from "moment";
import {
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.min.css";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Book from "../Blocks/Book";
import swal from "sweetalert";
import Skeleton from "react-loading-skeleton";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTab_selected:
        this.props.match.params.key == "editUser"
          ? 1
          : this.props.match.params.key == "BalanceStore"
          ? 2
          : this.props.match.params.key == "downloadBook"
          ? 3
          : 4,
      balanceTabsSelected: 1,
      bookPrice: 5,
      name: this.props.user.userName,
      email: this.props.user.userEmail,
      Password: "",
      phone: this.props.user.userPhone != null ? this.props.user.userPhone : "",
      image: this.props.user.userImage,
      newPassword: "",
      balanceLoader: true,
      points: [],
      pointsLoader: false,
      authEmail: "",
      authPhone: "",
      termsDownload: false,
      bookPayment: 1,
      bookCover: null,

      uploadBook: null,
      Category: [],
      CategoryLoader: true,
      catId: null,

      userPostsBook: [],
      userPostsBookLoader: true,
    };
  }

  componentWillMount() {
    this.getUserBalance();
    this.getPointList();
    this.getCategories();
    this.getScreensContent();
  }

  /**  getUserBalance Page */
  getUserBalance() {
    fetch(Global.api_link + "User_balance", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        this.setState({
          balance: result.balance,
          campaignBalance: result.campaign_balance,
          balanceLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  getUserBalance Page End */

  /**  getPointList Page */
  getPointList() {
    fetch(Global.api_link + "Points_list", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        this.setState({
          points: result.points,
          pointsLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  getPointList Page End */

  /**  getCategories Page */
  getCategories() {
    fetch(Global.api_link + "getCategories", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then(async (result) => {
        this.setState({
          Category: result.user,
          CategoryLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  getCategories Page End */

  render() {
    function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    return (
      <div className="profile">
        {/* header tabs section start  */}
        <div className="headerTabs">
          <Container>
            <ul>
              <li
                onClick={() => {
                  window.location.href = "/profile/editUser";
                  // this.setState({
                  //   headerTab_selected: 1,
                  // });
                }}
                className={this.state.headerTab_selected == 1 ? "bg_dark" : ""}
              >
                ملفي الشخصي
              </li>
              <li
                onClick={() => {
                  window.location.href = "/profile/BalanceStore";
                  // this.setState({
                  //   headerTab_selected: 2,
                  // });
                }}
                className={this.state.headerTab_selected == 2 ? "bg_dark" : ""}
              >
                متجر الرصيد
              </li>
              <li
                onClick={() => {
                  window.location.href = "/profile/downloadBook";

                  // this.setState({

                  //   headerTab_selected: 3,
                  // });
                }}
                className={this.state.headerTab_selected == 3 ? "bg_dark" : ""}
              >
                تحميل كتاب
              </li>
              <li
                onClick={() => {
                  window.location.href = "/profile/posts";

                  // this.setState({
                  //   headerTab_selected: 4,
                  // });
                }}
                className={this.state.headerTab_selected == 4 ? "bg_dark" : ""}
              >
                منشوراتي
              </li>
            </ul>
          </Container>
        </div>
        {/* header tabs section end  */}

        {/* edit user information section start  */}
        {this.state.headerTab_selected == 1 ? (
          <div className="editProfile">
            <Container>
              <div className="profile_form">
                {/* backgraound form start  */}
                <img
                  src="/img/Group 1051.png"
                  alt="k"
                  className="profile_form_bg"
                />
                {/* backgraound form start  */}
                {/* user img start  */}
                <div className="userImg">
                  <div
                    style={{
                      // background: `url(/img/userr.png)`,

                      background:
                        this.state.image != "" && this.state.image != null
                          ? !this.state.imageUploaded
                            ? `url(${Global.image_link + this.state.image})`
                            : `url(${this.state.blobImage})`
                          : !this.state.imageUploaded
                          ? "url(/img/userr.png)"
                          : `url(${this.state.blobImage})`,

                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <img src="/img/userr.png" alt="/" /> */}
                    <label htmlFor="pic">تغيير</label>

                    <input
                      className="custom-file-input"
                      type="file"
                      id="pic"
                      name="myfile"
                      accept="image/png, image/jpeg , image.jpg"
                      multiple
                      style={{ display: "none" }}
                      onChange={(image) => {
                        var path = (
                          window.URL || window.webkitURL
                        ).createObjectURL(image.target.files[0]);
                        this.setState({
                          oldPath: this.state.image,
                          image: image.target.value,
                          fileImage: image.target.files[0],
                          imageUploaded: true,

                          blobImage: path,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* user img end  */}
                {/* name input start  */}
                <input
                  type="text"
                  placeholder={this.props.i18n.name}
                  value={this.state.name.trim()}
                  onChange={(e) => {
                    this.setState({
                      name: e.target.value,
                    });
                  }}
                />
                {/* name input end  */}
                {/* email input start  */}
                <input
                  type="text"
                  placeholder={this.props.i18n.email}
                  className={this.state.emailEmpty ? "border_red" : ""}
                  onChange={(e) => {
                    this.setState(
                      {
                        email: e.target.value,
                        emailEmpty: false,
                      },
                      () => {
                        this.emailValidate(this.state.email);
                      }
                    );
                  }}
                  value={this.state.email.trim()}
                />
                {this.state.emailError && this.state.email != "" ? (
                  <span className="note-pass">الايميل غير صحيح</span>
                ) : null}
                {/* email input end  */}
                {/* phone input start  */}
                <input
                  type="text"
                  maxLength={10}
                  className={this.state.phoneEmpty ? "border_red" : ""}
                  placeholder={this.props.i18n.phone}
                  onChange={(e) => {
                    if (
                      isNumber(e.target.value) ||
                      e.target.value.length == 0
                    ) {
                      this.setState({
                        phone: e.target.value,
                        phoneEmpty: false,
                      });
                    }
                  }}
                  value={this.state.phone.trim()}
                />
                {/* {this.state.phone.length < 10 && this.state.phone != "" ? (
                  <span className="note-pass">
                    يجب ان يكون الرقم من 10 خانات
                  </span>
                ) : null} */}
                {/* phone input end  */}

                <p
                  onClick={() => {
                    this.setState({
                      changePasswordModal: true,
                    });
                  }}
                >
                  تغيير كلمة المرور
                </p>

                {/* <div
                  className="mainButton"
                  onClick={() => {
                    this.uploadImage();
                  }}
                >
      
                  <div></div> {this.props.i18n.save}
          
                </div> */}

                {this.state.submitLoader ? (
                  <div className="loginLoader">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="mainButton"
                    onClick={() => {
                      this.uploadImage();
                    }}
                  >
                    {/* submit button start  */}
                    <div></div> {this.props.i18n.save}
                    {/* submit button end  */}
                  </div>
                )}
              </div>
            </Container>
          </div>
        ) : null}

        {/* edit user information section end  */}

        {/* Balance store  section start  */}
        {this.state.headerTab_selected == 2 ? (
          <div className="BalanceStore">
            <Container>
              <div className="balanceTabs">
                <div className="buttonTabs">
                  <span
                    onClick={() => {
                      this.setState({
                        balanceTabsSelected: 1,
                      });
                    }}
                    className={
                      this.state.balanceTabsSelected == 1
                        ? "balanceTabsSelected"
                        : ""
                    }
                  >
                    {" "}
                    اكسب رصيد{" "}
                  </span>
                  <span
                    onClick={() => {
                      this.setState({
                        balanceTabsSelected: 2,
                      });
                    }}
                    className={
                      this.state.balanceTabsSelected == 2
                        ? "balanceTabsSelected"
                        : ""
                    }
                  >
                    {" "}
                    شراء رصيد{" "}
                  </span>
                </div>
                {this.state.balanceLoader ? null : (
                  <div className="balancees">
                    <div>
                      <img src="/img/coins2.png" alt="k" />
                      <span>الرصيد الفعلي</span>
                      <span>{this.state.balance}</span>
                    </div>
                    <div>
                      <img src="/img/coins3.png" alt="k" />
                      <span>الرصيد الترويجي</span>
                      <span>{this.state.campaignBalance}</span>
                    </div>
                  </div>
                )}
              </div>

              {/* Earn Balance section start  */}
              {this.state.balanceTabsSelected == 1 ? (
                <div className="EarnBalance">
                  <Container>
                    <Row>
                      {/* single cart earn balance start  */}
                      <Col sm={6} md={4} lg={3}>
                        <div
                          className="balance_cart"
                          onClick={() => {
                            this.setState({
                              showShears: true,
                            });
                          }}
                        >
                          <div>
                            <img src="/img/share-(3).png" alt="k" />
                            <p>شارك واربح</p>
                          </div>

                          <span>10 نقاط</span>
                        </div>
                      </Col>
                      {/* single cart earn balance end  */}
                      {/* single cart earn balance start  */}
                      <Col sm={6} md={4} lg={3}>
                        <div
                          className="balance_cart"
                          onClick={() => {
                            this.setState(
                              {
                                date: moment(new Date()).format("l"),
                              },
                              () => {
                                this.WatchVideo();
                              }
                            );
                          }}
                        >
                          <div>
                            <img src="/img/multimedia.png" alt="k" />
                            <p>شاهد كل يوم واحصل على </p>
                          </div>

                          <span>10 نقاط</span>
                        </div>
                      </Col>
                      {/* single cart earn balance end  */}
                      {/* single cart earn balance start  */}
                      <Col sm={6} md={4} lg={3}>
                        <div
                          className="balance_cart"
                          onClick={() => {
                            this.setState(
                              {
                                FacebookFollow: true,
                                FacebookFollowUsed: true,
                                fromFacebookLike: true,
                              },
                              () => {
                                if (!this.props.user.FacebookFollowUsed) {
                                  this.user_balance_charge_campaign();
                                }
                              }
                            );

                            // window.location.href = "www.facbppk.com";
                            // window.open("https://www.facebook.com", "_blank");
                          }}
                        >
                          <div>
                            <img src="/img/facebook-(2).png" alt="k" />
                            <p>تابعنا على الفيسبوك</p>
                          </div>

                          <span>10 نقاط</span>
                        </div>

                        {/* campaign Code Modal start */}

                        <Modal
                          show={this.state.FacebookFollow}
                          onHide={() => {
                            this.setState({
                              FacebookFollow: false,
                            });
                          }}
                          className="enterEmailModal"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>تابعنا على الفيسبوك</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {/* <div
                              class="fb-like"
                              data-href="https://developers.facebook.com"
                              data-width=""
                              data-layout="button_count"
                              data-action="like"
                              data-size="large"
                              data-share="false"
                            ></div> */}

                            <iframe
                              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&width=108&layout=button_count&action=like&size=large&share=false&height=21&appId"
                              style={{
                                width: "101px",
                                height: "28px",
                                overflow: "hidden",
                                border: "none",
                              }}
                              // data-kid-directed-site={true}

                              scrolling="no"
                              frameborder="0"
                              allowfullscreen="true"
                              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            ></iframe>
                          </Modal.Body>
                        </Modal>
                        {/* campaign Code Modal end  */}
                      </Col>
                      {/* single cart earn balance end  */}
                      {/* single cart earn balance start  */}
                      <Col sm={6} md={4} lg={3}>
                        <div
                          className="balance_cart"
                          onClick={() => {
                            this.setState({
                              campaignCodeModal: true,
                            });
                          }}
                        >
                          <div>
                            <img src="/img/coupon.png" alt="k" />
                            <p>لدي رمز ترويجي</p>
                          </div>

                          {/* <span>10 نقاط</span> */}
                        </div>
                      </Col>
                      {/* single cart earn balance end  */}
                    </Row>
                  </Container>
                </div>
              ) : null}
              {/* Earn Balance section end  */}

              {/* Buy balance section start  */}
              {this.state.balanceTabsSelected == 2 ? (
                <div className="BuyBalance">
                  <Container>
                    <Row>
                      {this.state.pointsLoader
                        ? null
                        : this.state.points.map((item, index) => {
                            {
                              /* single cart Buy balance start  */
                            }
                            return (
                              <Col sm={6} md={4} lg={3}>
                                <div
                                  className="BuyBalance_cart"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        amount: item.price,
                                        pointsCount: item.points,
                                      },
                                      () => {
                                        this.makeBalanceNewOrder();
                                      }
                                    );
                                  }}
                                >
                                  <div>
                                    <img src="/img/coins.png" alt="k" />
                                    <p>{item.points}</p>
                                    <p>نقطة</p>
                                    <span>{item.price} $</span>
                                  </div>

                                  <span>شراء</span>
                                </div>
                              </Col>
                            );
                            {
                              /* single cart Buy balance end  */
                            }
                          })}
                    </Row>
                  </Container>
                </div>
              ) : null}
              {/* Buy balance section end  */}
            </Container>
          </div>
        ) : null}
        {/* Balance store  section end  */}

        {/* download book section start  */}

        {this.state.headerTab_selected == 3 ? (
          <div className="downloadBook">
            <Container>
              <Row>
                <Col md={7}>
                  <div className="download_form">
                    {/* Book Name input start  */}
                    <input
                      type="text"
                      placeholder={this.props.i18n.BookName}
                      className={this.state.BookNameEmpty ? "border_red" : ""}
                      // value={this.state.BookName.trim()}
                      onChange={(e) => {
                        this.setState({
                          BookName: e.target.value,
                          BookNameEmpty: false,
                        });
                      }}
                      value={this.state.BookName}
                    />
                    {/* Book Name input end  */}

                    {/*Auth Name input start  */}
                    <input
                      type="text"
                      placeholder={this.props.i18n.AuthName}
                      className={this.state.AuthNameEmpty ? "border_red" : ""}
                      // value={this.state.AuthName.trim()}
                      onChange={(e) => {
                        this.setState({
                          AuthName: e.target.value,
                          AuthNameEmpty: false,
                        });
                      }}
                      value={this.state.AuthName}
                    />
                    {/*Auth Name input end  */}

                    {/*Category input start  */}
                    <select
                      className={
                        this.state.CategoryEmpty
                          ? " downloadBookSelect border_red"
                          : "downloadBookSelect"
                      }
                      style={
                        this.state.catId == null ? { color: "#a2a3bf" } : null
                      }
                      onChange={(e) => {
                        this.setState({
                          catId: e.target.value,
                          CategoryEmpty: false,
                        });
                      }}
                    >
                      <option
                        disabled
                        selected
                        style={{ backgroundColor: "silver" }}
                      >
                        اختر الفئة
                      </option>
                      {this.state.CategoryLoader
                        ? null
                        : this.state.Category.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                    </select>

                    {/*Category input end  */}

                    {/*auth email input start  */}
                    <div className="inputGroupValidate">
                      <input
                        type="text"
                        placeholder={this.props.i18n.email}
                        className={
                          this.state.authEmailEmpty ? "border_red" : ""
                        }
                        onChange={(e) => {
                          this.setState(
                            {
                              authEmail: e.target.value,
                              authEmailEmpty: false,
                            },
                            () => {
                              this.emailValidate(this.state.authEmail);
                            }
                          );
                        }}
                        value={this.state.authEmail.trim()}
                      />
                      {this.state.authEmailError &&
                      this.state.authEmail != "" ? (
                        <span className="note-pass">الايميل غير صحيح</span>
                      ) : null}
                    </div>
                    {/*auth email input end  */}

                    {/* auth phone start end  */}
                    <div className="inputGroupValidatePhone">
                      <input
                        type="text"
                        maxLength={10}
                        className={
                          this.state.authPhoneEmpty ? "border_red" : ""
                        }
                        placeholder={this.props.i18n.phone}
                        onChange={(e) => {
                          if (
                            isNumber(e.target.value) ||
                            e.target.value.length == 0
                          ) {
                            this.setState({
                              authPhone: e.target.value,
                              authPhoneEmpty: false,
                            });
                          }
                        }}
                        value={this.state.authPhone.trim()}
                      />

                      {this.state.authPhone.length < 10 &&
                      this.state.authPhone != "" ? (
                        <span className="note-pass">
                          يجب ان يكون الرقم من 10 خانات
                        </span>
                      ) : null}
                    </div>
                    {/* auth phone input end  */}

                    {/* aboutbook text area section start  */}
                    <textarea
                      placeholder={this.props.i18n.AboutBook}
                      className={this.state.AboutBookEmpty ? "border_red" : ""}
                      onChange={(e) => {
                        this.setState({
                          AboutBook: e.target.value,
                          AboutBookEmpty: false,
                        });
                      }}
                      value={this.state.AboutBook}
                    />
                    {/* aboutbook text area section end  */}

                    {/* book price section start  */}
                    <div className="bookPrice">
                      <span>السعر</span>
                      {/* types of payment start  */}
                      <div className="typeOfPayment">
                        <div>
                          <p
                            // className={this.state.agreeTermsEmpty ? "border-red" : ""}
                            onClick={() =>
                              this.setState({
                                bookPayment: 1,
                              })
                            }
                            className={
                              this.state.bookPayment == 1 ? "checked" : ""
                            }
                          >
                            {this.state.bookPayment == 1 ? (
                              <i className="fa fa-check"></i>
                            ) : null}
                          </p>
                          <span>مجاني</span>
                        </div>
                        <div>
                          <p
                            // className={this.state.bookPaymentEmpty ? "border-red" : ""}
                            onClick={() => this.setState({ bookPayment: 2 })}
                            className={
                              this.state.bookPayment == 2 ? "checked" : ""
                            }
                          >
                            {this.state.bookPayment == 2 ? (
                              <i className="fa fa-check"></i>
                            ) : null}
                          </p>
                          <span>مدفوع</span>
                        </div>
                      </div>
                      {/* types of payment end  */}

                      {/* custom price section start  */}

                      {/* {this.state.bookPayment == 2 ? ( */}
                      <div
                        className="custum_price"
                        style={
                          this.state.bookPayment == 2
                            ? { opacity: 1 }
                            : { opacity: 0 }
                        }
                      >
                        <span
                          onClick={() => {
                            this.setState({
                              bookPrice: this.state.bookPrice + 1,
                            });
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </span>
                        <input type="number" value={this.state.bookPrice} />
                        <span
                          onClick={() => {
                            if (this.state.bookPrice >= 1) {
                              this.setState({
                                bookPrice: this.state.bookPrice - 1,
                              });
                            }
                          }}
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                        <span className="doller">$</span>
                      </div>
                      {/* ) : null} */}
                      {/* custom price section end  */}
                    </div>
                    {/* book price section end  */}
                    {/* book download buttons section start  */}
                    <div className="downloadBook_buttons">
                      {/* uplade book cover button start  */}
                      <label
                        htmlFor="bookCover"
                        className={
                          this.state.bookCover != null
                            ? "bookCover"
                            : this.state.bookCoverError
                            ? "border_red"
                            : ""
                        }
                      >
                        تحميل غلاف الكتاب
                      </label>

                      <input
                        className="custom-file-input"
                        type="file"
                        id="bookCover"
                        name="myfile"
                        accept="image/png, image/jpeg , image.jpg"
                        multiple
                        style={{ display: "none" }}
                        onChange={(e) => {
                          var path = (
                            window.URL || window.webkitURL
                          ).createObjectURL(e.target.files[0]);

                          this.setState({
                            oldPath: this.state.image,
                            bookCover: e.target.value,
                            filebookCover: e.target.files[0],
                            // imageUploaded: true,

                            blobImage: path,
                          });
                          this.setState({
                            bookCoverError: false,
                          });
                        }}
                      />

                      {/* uplade book cover button start  */}

                      {/* uplade book  button start  */}
                      <label
                        htmlFor="uploadBook"
                        className={
                          this.state.uploadBook != null
                            ? "uploadBook"
                            : this.state.uploadBookError
                            ? "border_red"
                            : ""
                        }
                      >
                        تحميل الكتاب
                      </label>

                      <input
                        className="custom-file-input"
                        type="file"
                        id="uploadBook"
                        name="myfile"
                        accept="application/pdf,application/vnd.ms-excel"
                        multiple
                        style={{ display: "none" }}
                        onChange={(image) => {
                          var path = (
                            window.URL || window.webkitURL
                          ).createObjectURL(image.target.files[0]);
                          this.setState({
                            oldPath: this.state.image,
                            uploadBook: image.target.value,
                            fileuploadBook: image.target.files[0],
                            // imageUploaded: true,

                            // blobImage: path,
                          });
                          this.setState({
                            uploadBookError: false,
                          });
                        }}
                      />

                      {/* uplade book  button start  */}
                    </div>

                    {/* book download buttons section end  */}

                    {/* terms download checkbox section start  */}
                    <div className="termsDownload">
                      <p
                        // className={this.state.bookPaymentEmpty ? "border-red" : ""}
                        onClick={() =>
                          this.setState({
                            termsDownload: !this.state.termsDownload,
                            termsDownloadError: false,
                          })
                        }
                        className={
                          this.state.termsDownload
                            ? "checked"
                            : this.state.termsDownloadError
                            ? "border_red"
                            : ""
                        }
                      >
                        {this.state.termsDownload ? (
                          <i className="fa fa-check"></i>
                        ) : null}
                      </p>
                      <span>
                        قرات واوافق على سياسة{" "}
                        <a href="/PublicPage/conditions" target="_blank">
                          الشروط والاحكام
                        </a>
                      </span>
                    </div>
                    {/* terms download checkbox section end  */}

                    {/* submit button start  */}
                    <div className="downloadBookSubmit">
                      {this.state.submitLoader ? (
                        <div className="loginLoader">
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="mainButton"
                          onClick={() => {
                            this.uploadBookCover();
                          }}
                        >
                          <div></div> {this.props.i18n.send}
                        </div>
                      )}
                    </div>
                    {/* submit button end  */}
                  </div>
                </Col>
                <Col md={5}>
                  <div className="left_side">
                    <img src="/img/person.png" alt="l" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}

        {/* download book section end  */}

        {/* posts section start  */}
        {this.state.headerTab_selected == 4 ? (
          <div className="posts">
            <Container>
              <Row>
                {/* single post section satrt  */}
                {this.state.userPostsBookLoader ? (
                  <>
                    <Col md={6}>
                      <Skeleton height={150} />
                    </Col>
                    <Col md={6}>
                      <Skeleton height={150} />
                    </Col>
                  </>
                ) : (
                  this.state.userPostsBook.map((item, index) => {
                    return (
                      <Col md={6}>
                        <div className="postCart " key={index}>
                          <div className="right_side">
                            <img src={Global.image_link + item.cover} alt="k" />
                            <div className="nameBook_price">
                              {/* name of book start  */}
                              <p>{item.name}</p>
                              {/* name of book end  */}
                              {/* name of authr start  */}

                              <span>
                                {item.author == null
                                  ? "اسم المؤلف : - - -"
                                  : item.author}
                              </span>
                              {/* name of authr end  */}

                              {/* price start  */}

                              <div>
                                <span>{item.price}</span>
                                <span>دولار</span>
                              </div>
                              {/* price end  */}
                            </div>
                          </div>
                          {/* status of post start  */}

                          <div
                            className={
                              item.is_approved == 0
                                ? "left_side "
                                : item.is_approved == 1
                                ? "left_side left_side_Agree"
                                : "left_side left_side_REJECT"
                            }
                          >
                            <div></div>
                            <span>
                              {" "}
                              {item.is_approved == 0
                                ? "في انتظار الموافقة"
                                : item.is_approved == 1
                                ? "تمت الموافقة"
                                : "تم الرفض"}
                            </span>
                          </div>
                          {/* status of post end  */}
                        </div>
                      </Col>
                    );
                  })
                )}
                {/* single post section end  */}
              </Row>
            </Container>
          </div>
        ) : null}
        {/* posts section end  */}

        {/* change password modal start */}

        <Modal
          show={this.state.changePasswordModal}
          onHide={() => {
            this.setState({
              changePasswordModal: false,
            });
          }}
          className="enterEmailModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>تغيير كلمة المرور</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.props.i18n.newPassword}</h4>
            {/*  New Password input start  */}

            <input
              type="text"
              placeholder={this.props.i18n.newPassword}
              onChange={(e) => {
                this.setState({
                  newPassword: e.target.value,
                });
              }}
              value={this.state.newPassword.trim()}
            />
            {this.state.newPassword.length < 6 &&
            this.state.newPassword != "" ? (
              <span className="note-pass">{this.props.i18n.mustBeMore6}</span>
            ) : null}
            {/*  New Password input end  */}

            <h4>{this.props.i18n.ConfirmNewPassword}</h4>

            {/* Confirm New Password input start  */}

            <input
              type="text"
              placeholder={this.props.i18n.ConfirmNewPassword}
              onChange={(e) => {
                this.setState({
                  ConfirmNewPassword: e.target.value,
                  PasswordEmpty: false,
                });
              }}
            />

            {/* Confirm New Password input end  */}
          </Modal.Body>
          <Modal.Footer>
            <div
              className="sendEmail"
              onClick={() => {
                this.isMatch();
              }}
            >
              ارسال
            </div>
          </Modal.Footer>
        </Modal>
        {/* change password modal end  */}

        {/* modal shear  start */}
        <Modal
          className="shareModal"
          show={this.state.showShears}
          onHide={() => this.setState({ showShears: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.i18n.ShareWithFriends}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ShareRow">
              {/* <h5>مشاركة </h5> */}
              <ul>
                <a>
                  <li>
                    <FacebookShareButton
                      url={Global.siteLink}
                      separator=":: "
                      // title={this.state.Details.title}
                      quote={"Look at this amazing book from Noon"}
                    >
                      <FacebookIcon
                        size={32}
                        round
                        onClick={() => {
                          this.setState(
                            {
                              socialShareUsed: true,
                              fromSocialShare: true,
                            },
                            () => {
                              if (!this.props.user.socialShareUsed) {
                                this.user_balance_charge_campaign();
                              }
                            }
                          );
                        }}
                      />
                    </FacebookShareButton>
                  </li>
                </a>
                <a>
                  <li>
                    <WhatsappShareButton
                      url={Global.siteLink}
                      separator=":: "
                      // title={this.state.Details.title}
                    >
                      <WhatsappIcon
                        size={32}
                        round
                        onClick={() => {
                          this.setState(
                            {
                              socialShareUsed: true,
                              fromSocialShare: true,
                            },
                            () => {
                              if (!this.props.user.socialShareUsed) {
                                this.user_balance_charge_campaign();
                              }
                            }
                          );
                        }}
                      />
                    </WhatsappShareButton>
                  </li>
                </a>
                <a>
                  <li>
                    <EmailShareButton
                      url={Global.siteLink}
                      // subject={this.state.Details.title}
                      body="I want you to see this book from Noon "
                      separator=":: "
                      // title={this.state.Details.title}
                    >
                      <EmailIcon
                        size={32}
                        round
                        onClick={() => {
                          this.setState(
                            {
                              socialShareUsed: true,
                              fromSocialShare: true,
                            },
                            () => {
                              if (!this.props.user.socialShareUsed) {
                                this.user_balance_charge_campaign();
                              }
                            }
                          );
                        }}
                      />
                    </EmailShareButton>
                  </li>
                </a>
                {/* <a title="Copy Link">
                  <li
                    onClick={() =>
                      this
                        .copyPageUrl
                        // Global.shareLink + this.props.productData.id
                        ()
                    }
                  >
                    <img src="/img/copy.png"></img>
                  </li>
                </a> */}
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        {/* modal shear  end */}
        {/*##############  modal video section start  ########### */}
        <ModalVideo
          // channel={
          //   this.state.BookDetails.youtube_link != "" ? "youtube" : "custom"
          // }
          channel="custom"
          autoplayp
          isOpen={this.state.isOpen}
          url={Global.image_link + this.state.videoLink}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
        />
        {/*##############  modal video section end    ########### */}

        {/* campaign Code Modal start */}

        <Modal
          show={this.state.campaignCodeModal}
          onHide={() => {
            this.setState({
              campaignCodeModal: false,
            });
          }}
          className="enterEmailModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.i18n.campaignCode}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>أدخل الرمز</h4>
            <input
              type="text"
              placeholder={this.props.i18n.campaignCode}
              onChange={(e) => {
                this.setState({
                  campaignCode: e.target.value,
                });
              }}
              value={this.state.campaignCode}
            />
          </Modal.Body>
          <Modal.Footer>
            <div
              className="sendEmail"
              onClick={(e) => {
                this.setState(
                  {
                    // coponCode: e.target.value,
                  },
                  () => {
                    this.User_copouns();
                  }
                );
              }}
            >
              ارسال
            </div>
          </Modal.Footer>
        </Modal>
        {/* campaign Code Modal end  */}
      </div>
    );
  }

  /** upload book cover function and  api  start */

  uploadBookCover() {
    this.setState(
      {
        submitLoader: true,
      },
      () => {
        if (this.state.bookCover != null) {
          let localUri = this.state.bookCover;
          let fileImage = localUri.split("/").pop();
          // Infer the type of the image
          let match = /\.(\w+)AED/.exec(fileImage);
          let type = match ? `image/AED{match[1]}` : `image`;
          // Upload the image using the fetch and FormData APIs

          let formData = new FormData();

          formData.append("photo", this.state.filebookCover);

          fetch(Global.api_link + "uploadImage", {
            method: "POST",
            body: formData,
          })
            .then((response) => response.json())
            .then((result) => {
              this.setState(
                {
                  bookCover: result,
                },
                () => {
                  this.uploadBook();
                }
              );
            });
        } else {
          this.uploadBook();
        }
      }
    );
  }
  /** upload book cover function and  api   end*/

  /** upload book  function and  api  start */

  uploadBook() {
    this.setState(
      {
        submitLoader: true,
      },
      () => {
        if (this.state.uploadBook != null) {
          let localUri = this.state.uploadBook;
          let fileImage = localUri.split("/").pop();
          // Infer the type of the image
          let match = /\.(\w+)AED/.exec(fileImage);
          let type = match ? `image/AED{match[1]}` : `image`;
          // Upload the image using the fetch and FormData APIs

          let formData = new FormData();

          formData.append("photo", this.state.fileuploadBook);

          fetch(Global.api_link + "uploadImage", {
            method: "POST",
            body: formData,
          })
            .then((response) => response.json())
            .then((result) => {
              this.setState(
                {
                  uploadBook: result,
                },
                () => {
                  this.AddBook();
                }
              );
            });
        } else {
          this.AddBook();
        }
      }
    );
  }
  /** upload book  function and  api   end*/

  //  EditProfile api  start
  EditProfile() {
    this.setState(
      {
        submitLoader: true,
      },
      () => {
        fetch(Global.api_link + "ProfileEdit", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.state.email,
            image: this.state.image,
            name: this.state.name,
            phone: this.state.phone,
            user_id: this.props.user.UID,
          }),
        })
          .then((response) => response.json())
          .then(async (result) => {
            this.setState(
              {
                submitLoader: false,
              },
              async () => {
                if (result.data == false) {
                  swal({
                    title: this.props.i18n.sorry,
                    text: result.message,
                    icon: "warning",
                    button: {
                      text: this.props.i18n.ok,
                    },
                    // className: "addAccountSwal",
                  });
                } else {
                  this.getUserInfo();
                }
              }
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  }
  //  EditProfile api  end

  /**  get user info Page */
  getUserInfo() {
    fetch(Global.api_link + "getuser", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        let { actions } = this.props;
        await actions.setUser({
          ...this.props.user,
          userName: result.user[0].name,
          userEmail: result.user[0].email,
          userPhone: result.user[0].phone,
          userImage: result.user[0].image,

          // access_token: result.data.access_token,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  get user info Page End */

  /**  user_balance_charge_campaign Page */
  user_balance_charge_campaign() {
    fetch(Global.api_link + "user_balance_charge_campaign", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        points: 10,
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        if (this.state.fromFacebookLike) {
          let { actions } = this.props;
          await actions.setUser({
            ...this.props.user,
            FacebookFollowUsed: true,
          });
          this.setState({
            fromFacebookLike: false,
          });
          this.getUserBalance();
        }

        if (this.state.fromSocialShare) {
          let { actions } = this.props;
          await actions.setUser({
            ...this.props.user,
            socialShareUsed: true,
          });
          this.setState({
            fromSocialShare: false,
          });
          this.getUserBalance();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  user_balance_charge_campaign Page End */

  /**  make Balance New Order Page */
  makeBalanceNewOrder() {
    fetch(Global.api_link + "makeBalanceNewOrder", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        amount: this.state.amount,
        points: this.state.pointsCount,
        txn_id: parseInt(Date.now()),
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        window.location.href = result.payment_link;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  make Balance New Order Page End */

  /**  User_copouns Page */
  User_copouns() {
    fetch(Global.api_link + "User_copouns", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        code: this.state.campaignCode,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data == false) {
          swal({
            title: this.props.i18n.sorry,
            text: "الرمز غير صحيح",
            icon: "warning",
            button: {
              text: this.props.i18n.ok,
            },
            // className: "addAccountSwal",
          }).then(() => {
            this.setState({
              campaignCode: "",
            });
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  User_copouns Page End */

  /**  Add Book Page */
  AddBook() {
    if (
      this.state.authEmail == "" ||
      this.state.authEmail == null ||
      this.state.authPhone == "" ||
      this.state.authPhone == null ||
      this.state.AuthName == "" ||
      this.state.AuthName == null ||
      this.state.BookName == "" ||
      this.state.BookName == null ||
      this.state.catId == "" ||
      this.state.catId == null ||
      this.state.AboutBook == "" ||
      this.state.AboutBook == null ||
      this.state.uploadBook == "" ||
      this.state.uploadBook == null ||
      this.state.bookCover == "" ||
      this.state.bookCover == null ||
      this.state.authEmailError ||
      this.state.authPhone.length < 10 ||
      this.state.termsDownload == false
    ) {
      if (this.state.authEmail == "" || this.state.authEmail == null) {
        this.setState({
          authEmailEmpty: true,
        });
      }
      if (this.state.authPhone == "" || this.state.authPhone == null) {
        this.setState({
          authPhoneEmpty: true,
        });
      }
      if (this.state.AuthName == "" || this.state.AuthName == null) {
        this.setState({
          AuthNameEmpty: true,
        });
      }
      if (this.state.BookName == "" || this.state.BookName == null) {
        this.setState({
          BookNameEmpty: true,
        });
      }
      if (this.state.catId == "" || this.state.catId == null) {
        this.setState({
          CategoryEmpty: true,
        });
      }
      if (this.state.AboutBook == "" || this.state.AboutBook == null) {
        this.setState({
          AboutBookEmpty: true,
        });
      }
      if (this.state.termsDownload == false) {
        this.setState({
          termsDownloadError: true,
        });
      }

      if (this.state.uploadBook == "" || this.state.uploadBook == null) {
        this.setState({
          uploadBookError: true,
        });
      }

      if (this.state.bookCover == "" || this.state.bookCover == null) {
        this.setState({
          bookCoverError: true,
        });
      }

      this.setState({
        submitLoader: false,
      });
    } else {
      fetch(Global.api_link + "AddBook", {
        method: "POSt",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: this.props.user.UID,
          book_name: this.state.BookName,
          author_name: this.state.AuthName,
          phone: this.state.authPhone,
          email: this.state.authEmail,
          cat_id: this.state.catId,
          about_book: this.state.AboutBook,
          price: this.state.bookPayment == 1 ? 0 : this.state.bookPrice,
          link: this.state.uploadBook,
          cover: this.state.bookCover,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState(
            {
              BookName: "",
              AuthName: "",
              authPhone: "",
              authEmail: "",
              catId: null,
              bookPayment: 1,
              uploadBook: null,
              bookCover: null,
              termsDownload: false,
              AboutBook: "",
              submitLoader: false,
            },
            () => {
              swal({
                title: this.props.i18n.done,
                text: "تم ارسال كتابك للإدارة",
                icon: "success",
                button: {
                  text: this.props.i18n.ok,
                },
              }).then(() => {
                window.location.href = "/profile/posts";
              });
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  /**  Add Book Page End */

  /**  update  image function and  api  start */

  uploadImage() {
    this.setState(
      {
        submitLoader: true,
      },
      () => {
        if (this.state.image != null) {
          let localUri = this.state.image;
          let fileImage = localUri.split("/").pop();
          // Infer the type of the image
          let match = /\.(\w+)AED/.exec(fileImage);
          let type = match ? `image/AED{match[1]}` : `image`;
          // Upload the image using the fetch and FormData APIs

          let formData = new FormData();

          formData.append("photo", this.state.fileImage);

          fetch(Global.api_link + "uploadImage", {
            method: "POST",
            body: formData,
          })
            .then((response) => response.json())
            .then((result) => {
              if (this.state.imageUploaded) {
                this.setState(
                  {
                    image: result,
                  },
                  () => {
                    this.EditProfile();
                  }
                );
              } else {
                this.EditProfile();
              }
            });
        } else {
          this.EditProfile();
        }
      }
    );
  }
  /** End update  image function and  api  */

  /**  Watch_video Page */
  WatchVideo() {
    fetch(Global.api_link + "Watch_video", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.ads == null) {
          swal({
            title: this.props.i18n.sorry,
            text: "هذه الخدمة غير متوفرة الان",
            icon: "warning",
            button: {
              text: this.props.i18n.ok,
            },
          });
        } else {
          this.setState(
            {
              videoLink: result.ads.video_ads,
              isOpen: true,
            },
            () => {
              if (this.state.date == moment(new Date()).format("l")) {
              } else {
                this.user_balance_charge_campaign();
              }
            }
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  Watch_video Page End */

  /**  getScreensContent Page */
  getScreensContent() {
    fetch(Global.api_link + "getScreensContent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        screen_id: 9,
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          userPostsBook: result.Content,
          userPostsBookLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  getScreensContent Page End */

  // is match function start
  isMatch() {
    if (this.state.newPassword == this.state.ConfirmNewPassword) {
      this.changePassword();
    } else {
      swal({
        title: this.props.i18n.sorry,
        text: "كلمة المرور غير متطابقة",
        icon: "warning",
        button: {
          text: this.props.i18n.ok,
        },
        // className: "addAccountSwal",
      });
    }
  }

  // is match function end

  /**  change Password Page */
  changePassword() {
    fetch(Global.api_link + "EditPassword", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        password: this.state.newPassword,
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        swal({
          title: this.props.i18n.done,
          text: "تم تغيير كلمة المرور",
          icon: "success",
          button: {
            text: this.props.i18n.ok,
          },
          // className: "addAccountSwal",
        }).then(() => {
          this.setState({
            changePasswordModal: false,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  change Password Page End */

  //// email validation function
  validateEmailRegular(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  emailValidate(value) {
    // var value = this.state.email;
    if (!this.validateEmailRegular(value)) {
      if (this.state.headerTab_selected == 3) {
        this.setState({
          authEmailError: true,
          // emailValidate: value + this.props.i18n.notValidEmail,
        });
      } else {
        this.setState({
          emailError: true,
          emailValidate: value + this.props.i18n.notValidEmail,
        });
      }
    } else {
      this.setState({
        emailError: false,
        authEmailError: false,

        // emailValidate: this.props.i18n.insertEmail,
      });
    }
  }
  //// end email validation function
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
