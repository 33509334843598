import React, { Component } from "react";
import {
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
  Dropdown,
} from "react-bootstrap";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      keyVal: null,
    };
  }

  componentWillMount() {
    this.getUserInfo();
    this.getSideMenuWeb();
  }

  /**  get user info Page */
  getUserInfo() {
    fetch(Global.api_link + "getuser", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        // console.log(result, "heh");

        let { actions } = this.props;
        await actions.setUser({
          ...this.props.user,
          userName: result.user[0].name,
          userEmail: result.user[0].email,
          userPhone: result.user[0].phone,
          userImage: result.user[0].image,
          // FacebookFollowUsed: this.props.user.FacebookFollowUsed,

          // access_token: result.data.access_token,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get user info Page End */

  /** get Side Menu Web */
  getSideMenuWeb() {
    fetch(Global.api_link + "getSideMenuWeb", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          options: result.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /** get Side Menu Web End */

  /// handle toggle
  handleSidebarToggle = (isOpen) => {
    this.setState({ isOpen });
  };
  render() {
    return (
      <div className="header">
        <Container>
          <Row>
            {/* right side of header (logo & side menu img ) start  */}
            <Col xs={5} sm={4} className="margin-auto">
              <div className="logo_SideMenu">
                <img
                  src="/img/Group862.png"
                  alt="l"
                  onClick={() => this.handleSidebarToggle(true)}
                  className="burgerMenu"
                />
                <MultilevelSidebar
                  open={this.state.isOpen}
                  onToggle={this.handleSidebarToggle}
                  options={this.state.options}
                  header={
                    <div className="sideLogo">
                      <a href="/">
                        <img src="/img/Group20.png" alt="l" />
                      </a>
                    </div>
                  }
                  // onItemClick={this.handleClick}
                />
                <a href="/">
                  <img src="/img/Group20.png" alt="l" />
                </a>
              </div>
            </Col>
            {/* right side of header (logo & side menu img ) end  */}
            {/* center side of header (logo of library ) start  */}

            <Col
              xs={1}
              sm={2}
              className="margin-auto no-padd"
              style={{ zIndex: "99" }}
            >
              <div className="library_logo">
                <img
                  src="/img/Group1043.png"
                  alt="l"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                />
              </div>
            </Col>
            {/* center side of header (logo of library ) end  */}

            {/* left side of header (search & userName or login buttons ) start  */}

            <Col xs={6} md={6} className="margin-auto">
              <div className="search_userButtons">
                {/* search input  start  */}
                <InputGroup className="justForweb_headerSearch">
                  <FormControl
                    placeholder="إبحث عن كتابك "
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(key) => {
                      this.setState({ keyVal: key.target.value });
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (this.state.keyVal == null) {
                        } else {
                          this.Search(this.state.keyVal);
                        }
                      }
                    }}
                  />
                  <InputGroup.Prepend>
                    <i
                      className="fas fa-search"
                      onClick={() => {
                        if (this.state.keyVal == null) {
                        } else {
                          this.Search(this.state.keyVal);
                        }
                      }}
                    ></i>
                  </InputGroup.Prepend>
                </InputGroup>
                {/* search input  start  */}

                {/* user dropdown && login buttouns section start  */}

                <div className="userDropdown">
                  {this.props.user.UID == 69 ? (
                    //  {/* login and register buttons (if not loggedIn) start  */}
                    <>
                      {/* login button start  */}
                      <a
                        href="/login"
                        className={
                          window.location.pathname !== "/register"
                            ? "logInButt butSelected"
                            : "logInButt"
                        }
                      >
                        تسجيل دخول
                      </a>
                      {/* login button end  */}
                      {/* register  button start  */}

                      <a
                        href="/Register"
                        className={
                          window.location.pathname === "/register"
                            ? "logInButt butSelected"
                            : "logInButt"
                        }
                      >
                        إنشاء حساب
                      </a>
                      {/* register  button end  */}
                    </>
                  ) : (
                    //  {/* login and register buttons (if not loggedIn) end  */}

                    <>
                      {/* user img start  */}
                      <img
                        className="userImg"
                        src={
                          this.props.user.userImage != null
                            ? Global.image_link + this.props.user.userImage
                            : "/img/user (1).png"
                        }
                        alt="k"
                      />
                      {/* user img end  */}

                      <Dropdown>
                        {/* user name  */}
                        <Dropdown.Toggle id="dropdown-basic">
                          {this.props.user.userName}
                          {/* down Arrow img start  */}
                          <img src="/img/Group1051.png" alt="k" />
                          {/* down Arrow img end  */}
                        </Dropdown.Toggle>
                        {/* user name  */}
                        <Dropdown.Menu>
                          {/* overlay bg list section start  */}
                          <div className="shadow_list"></div>

                          {/* overlay bg list section end  */}
                          {/* my Account drobdown item start  */}
                          <Dropdown.Item href="/profile/editUser">
                            <div>
                              <img src="/img/user.png" alt="l" />
                              <span>ملفي الشخصي</span>
                            </div>
                          </Dropdown.Item>
                          {/* my Account drobdown item end  */}
                          {/* Balance store drobdown item start  */}

                          <Dropdown.Item href="/profile/BalanceStore">
                            <div>
                              <img src="/img/dollar.png" alt="l" />
                              <span> متجر الرصيد</span>
                            </div>
                          </Dropdown.Item>
                          {/* Balance store drobdown item end  */}
                          {/* Download book drobdown item start  */}

                          <Dropdown.Item href="/profile/downloadBook">
                            <div>
                              <img src="/img/add.png" alt="l" />
                              <span> تحميل كتاب</span>
                            </div>
                          </Dropdown.Item>
                          {/* Download book drobdown item end  */}

                          {/* My posts drobdown item start  */}

                          <Dropdown.Item href="/profile/posts">
                            <div>
                              <img src="/img/bill.png" alt="l" />
                              <span>منشوراتي</span>
                            </div>
                          </Dropdown.Item>

                          {/* My posts drobdown item end  */}

                          {/* My laibrary drobdown item start  */}

                          <Dropdown.Item href="/favorities/MyLibrary">
                            <div>
                              <img src="/img/my_books.png" alt="l" />
                              <span>{this.props.i18n.MyLibrary}</span>
                            </div>
                          </Dropdown.Item>

                          {/* My fav laibrary item end  */}

                          {/* My fav drobdown item start  */}

                          <Dropdown.Item href="/favorities/Favorite">
                            <div>
                              <img src="/img/favorite.png" alt="l" />
                              <span>{this.props.i18n.Favorite}</span>
                            </div>
                          </Dropdown.Item>

                          {/* My fav fav item end  */}

                          {/* My history drobdown item start  */}

                          <Dropdown.Item href="/favorities/history">
                            <div>
                              <img src="/img/history.png" alt="l" />
                              <span>{this.props.i18n.history}</span>
                            </div>
                          </Dropdown.Item>

                          {/* My fav history item end  */}

                          {/* logout drobdown item start  */}

                          <Dropdown.Item
                            onClick={async () => {
                              const { actions } = this.props;
                              await actions.setUser({
                                ...this.props.user,
                                UID: 69,
                                userName: "",
                                userEmail: "",
                                access_token: "",
                                userImage: "",
                                userPhone: "",
                                FacebookFollowUsed: false,
                                socialShareUsed: false,
                              });
                              window.location.href = "/login";
                            }}
                          >
                            <div>
                              <img src="/img/logout.png" alt="l" />
                              <span> خروج</span>
                            </div>
                          </Dropdown.Item>
                          {/* logout drobdown item end  */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                </div>
              </div>
            </Col>

            <Col xs={12} className="justForTablet_mobile">
              <div className="search_userButtons">
                {/* search input  start  */}
                <InputGroup>
                  <FormControl
                    placeholder="إبحث عن كتابك من هنا"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Prepend>
                    <i className="fas fa-search"></i>
                  </InputGroup.Prepend>
                </InputGroup>
                {/* search input  start  */}
              </div>
            </Col>

            {/* left side of header (search & userName or login buttons ) end  */}
          </Row>
        </Container>
      </div>
    );
  }

  Search(key) {
    if (key.includes("%")) {
      window.location.href = "/Search/" + key.replace("%", "%25");
    } else {
      window.location.href = "/Search/" + key;
    }
  }
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
