import React, { Component } from "react";
import { Col, Container, Row, Carousel } from "react-bootstrap";
import Carousell from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ItemsCarousel from "react-items-carousel";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Book from "../Blocks/Book";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1200, min: 768 },
          items: 3,
        },
        mobile: {
          breakpoint: { max: 768, min: 576 },
          items: 2,
        },
        Sm_mobile: {
          breakpoint: { max: 576, min: 0 },
          items: 1,
        },
      },
      sectionsResponsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1200, min: 768 },
          items: 3,
        },
        mobile: {
          breakpoint: { max: 768, min: 576 },
          items: 2,
        },
        Sm_mobile: {
          breakpoint: { max: 576, min: 0 },
          items: 1,
        },
      },
      Categories: [],
      CategoriesLoader: true,

      // section1: {},
      // section2: {},
      // section3: {},

      sections: [],
      sectionsLoader: true,

      settingLoader: true,

      slider: [],
      sliderLoader: true,
    };
  }
  componentWillMount() {
    this.getLibraryCategories();
    this.get_home_data_Noor();
    this.getsetting();
    this.GetSlider();
  }

  /**  get Library Categories Page */
  getLibraryCategories() {
    fetch(Global.api_link + "getLibraryCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        library_id: 1,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Categories: result.Categories,
          CategoriesLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get Library Categories Page End */

  /**  get_home_data_Noor Page */
  get_home_data_Noor() {
    fetch(Global.api_link + "get_home_data_Noor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          sections: result.sections,
          // section1: result.sections[0],
          // section2: result.sections[1],
          // section3: result.sections[2],
          sectionsLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get_home_data_Noor Page End */

  /**  get setting start */
  getsetting() {
    fetch(Global.api_link + "GetAboutAndPrivacy", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          emailLib: result.settings[0].email,
          phoneLIb: result.settings[0].phone,
          mission: result.settings[0].mission,
          vision: result.settings[0].vision,
          messageSite: result.settings[0].message,
          settingLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  get setting  End */

  /**  Get Sliderstart */
  GetSlider() {
    fetch(Global.api_link + "GetSlider", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          slider: result.slider,
          sliderLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  Get Slider End */

  render() {
    return (
      <div className="home">
        {/* landing page section start  */}
        <Container>
          {/* <div className="landingPage">
            {/* landing   paragraph  section start  
            <div className="landing_paragraph">
              <img src="/img/Group17.png" alt="l" />

              {/* landing buttons section start  
              {this.props.user.UID == 69 ? (
                <div className="landingPage_buttons">
                  {/* login button start  
                  <a href="/login">
                    <div></div> تسجيل دخول
                  </a>
                  {/* login button end  
                  {/* register  button start  

                  <a href="/Register">
                    <div></div>إنشاء حساب
                  </a>
                  {/* register  button end  
                </div>
              ) : null}
              {/* landing buttons section end
            </div>
            {/* landing   paragraph  section end  
          </div> */}
          {this.state.sliderLoader ? (
            <Skeleton duration={2} height={400} />
          ) : this.state.slider.length < 1 ? null : (
            <div className="landingPage">
              <Carousel
                indicators={false}
                controls={this.state.slider.length > 1}
              >
                {this.state.slider.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        src={Global.image_link + item.image}
                        alt="l"
                        className="landing-page-img"
                      />
                      {item.link != null ? (
                        <span
                          onClick={() => {
                            window.location.href = item.link;
                          }}
                        >
                          رؤية المزيد
                        </span>
                      ) : null}
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}
        </Container>

        {/* landing page section end  */}

        {/* libraries section start  */}

        <div className="libraries">
          <Container>
            {/* libraries button section start  */}
            <a className="libraries_top" href="#lib">
              <h2>تصفح المكتبات</h2>
              <img src="/img/play.png" alt="l" />
            </a>
            {/* libraries button section end  */}
            <div className="librariesCat_items" id="lib">
              {this.state.CategoriesLoader ? (
                <div>
                  <Skeleton height={150} />
                </div>
              ) : (
                <Carousell
                  responsive={this.state.responsive}
                  showDots={true}
                  infinite={true}
                  // showDots={false}
                  arrows={false}
                  style={{ direction: "ltr" }}
                  // className={this.state.categories.length < 12 ? "rtl" : ""}
                >
                  {this.state.Categories.map((item, index) => {
                    {
                      /* library category single item section start  */
                    }
                    return (
                      <a
                        href={"/categories/" + item.id}
                        className="libraryCat"
                        key={index}
                        // style={{
                        //   background: "url(./img/ww.png)",
                        //   backgroundRepeat: "no-repeat",
                        //   backgroundSize: "contain",
                        // }}
                      >
                        <img src={Global.image_link + item.image} alt="l" />
                        {/* <p>{item.name}</p> */}
                      </a>
                    );
                    {
                      /* library category single item section start  */
                    }
                  })}
                </Carousell>
              )}
            </div>
          </Container>
        </div>
        {/* libraries section end  */}

        {/* user history && fav && library section  start  */}
        {this.props.user.UID == 69 ? null : (
          <div className="userFav_his_lib">
            <Container>
              <Row className="userFav_his_lib_row">
                {/* my library section start  */}
                <Col sm={4}>
                  <a href="/favorities/MyLibrary">
                    <img src="/img/my_books@noon.png" alt="l" />
                    <span>{this.props.i18n.MyLibrary}</span>
                  </a>
                </Col>
                {/* my library section end  */}
                {/* my favorits section start  */}

                <Col sm={4}>
                  <a href="/favorities/Favorite">
                    <img src="/img/favorite@noon.png" alt="l" />
                    <span>{this.props.i18n.Favorite}</span>
                  </a>
                </Col>
                {/* my favorits section end  */}
                {/* my history section start  */}

                <Col sm={4}>
                  <a href="/favorities/history">
                    <img src="/img/history@noon.png" alt="l" />
                    <span>{this.props.i18n.history}</span>
                  </a>
                </Col>
                {/* my history section end  */}
              </Row>
            </Container>
          </div>
        )}

        {/* user history && fav && library section  end  */}

        {/* our goals section start  */}
        <div className="ourGoals">
          <Container>
            <Row>
              {/* single goal card section start  */}
              <Col sm={3} className="margin-top-auto">
                <div className="single_goal">
                  <img
                    src="/img/Intersection.png"
                    alt="l"
                    className="groveImg"
                  />
                  <div className="right_side">
                    <h5>{this.props.i18n.OurGoal}</h5>
                    {this.state.settingLoader ? null : (
                      <p>{this.MyComponent(this.state.mission)}</p>
                    )}
                  </div>
                  <div className="left_side">
                    <img src="/img/aim.png" alt="l" />
                  </div>
                </div>
              </Col>
              {/* single goal card section end  */}
              {/* single goal card section start  */}
              <Col sm={6} className="margin-top-auto">
                <div
                  className="single_goal single_goal-center"
                  style={{ backgroundColor: "#00B88C" }}
                >
                  <img
                    src="/img/Intersection.png"
                    alt="l"
                    className="groveImg"
                  />
                  <div className="right_side">
                    <h5>{this.props.i18n.OurMission}</h5>

                    {this.state.settingLoader ? null : (
                      <p>{this.MyComponent(this.state.messageSite)}</p>
                    )}

                    {/* <h6>إحداث نهضة في القارءة عبر وصول مشروع مكتبة نون</h6> */}
                  </div>
                  <div className="left_side">
                    <img src="/img/lamp.png" alt="l" />
                  </div>
                </div>
              </Col>
              {/* single goal card section end  */}
              {/* single goal card section start  */}
              <Col sm={3} className="margin-top-auto">
                <div className="single_goal single_goal-last">
                  <img
                    src="/img/Intersection.png"
                    alt="l"
                    className="groveImg"
                  />
                  <div className="right_side">
                    <h5>{this.props.i18n.OurVision}</h5>
                    {this.state.settingLoader ? null : (
                      <p>{this.MyComponent(this.state.vision)}</p>
                    )}
                  </div>
                  <div className="left_side">
                    <img src="/img/eye.png" alt="l" />
                  </div>
                </div>
              </Col>
              {/* single goal card section end  */}
            </Row>
          </Container>
        </div>

        {/* our goals section end  */}

        {/* latest version section start  */}

        {this.state.sectionsLoader ? (
          <div style={{ margin: "40px 0" }}>
            <Container>
              <Skeleton height={250} />
              <Skeleton height={250} />
              <Skeleton height={250} />
            </Container>
          </div>
        ) : (
          this.state.sections.map((item, index) => {
            return (
              <div
                className={
                  index % 2 ? "latestVersion" : "latestVersion bestSeller"
                }
                // other class name make other color "HighestRated"
                key={index}
              >
                <div className="carousel_title">
                  <Container>
                    <h4>{item.name}</h4>
                  </Container>
                </div>
                <Container>
                  <Carousell
                    responsive={this.state.sectionsResponsive}
                    showDots={true}
                    infinite={true}
                    showDots={false}
                    // className={this.state.categories.length < 12 ? "rtl" : ""}
                  >
                    {item.books.map((book, bookIndex) => {
                      {
                        /* single book card start  */
                      }
                      return <Book key={bookIndex} bookData={book} />;
                      {
                        /* single book card end  */
                      }
                    })}
                  </Carousell>
                </Container>
              </div>
            );
          })
        )}

        {/* latest version section end  */}

        {/* countact us section start  */}
        <div className="contactUs">
          <Container>
            <Row>
              <Col md={6}>
                <div className="countactUs_img">
                  <img src="/img/Group814.png" alt="k" />
                  {/* <div className="OtherCountact">
                    <span>{this.props.i18n.OrThrough}</span>

                    <a href={"mailto:" + this.state.emailLib}>
                      {this.state.settingLoader ? null : this.state.emailLib}
                    </a>
                    <a href={"tel:" + this.state.phoneLIb}>
                      {this.state.settingLoader ? null : this.state.phoneLIb}
                    </a>
                  </div> */}
                </div>
              </Col>
              <Col md={6}>
                <div className="countactUs_form">
                  <img
                    src="/img/Group 746.png"
                    alt="k"
                    className="countactUs_form_bg"
                  />
                  <h4>{this.props.i18n.ConnectUs}</h4>
                  <input
                    type="text"
                    placeholder={this.props.i18n.email}
                    value={
                      this.props.user.UID == 69
                        ? this.state.email
                        : this.props.user.userEmail
                    }
                    disabled={this.props.user.UID == 69 ? false : true}
                    onChange={(e) => {
                      this.setState({
                        email: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    placeholder={this.props.i18n.name}
                    value={
                      this.props.user.UID == 69
                        ? this.state.name
                        : this.props.user.userName
                    }
                    disabled={this.props.user.UID == 69 ? false : true}
                    onChange={(e) => {
                      this.setState({
                        name: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    maxLength={10}
                    placeholder={this.props.i18n.phone}
                    value={
                      this.props.user.UID == 69
                        ? this.state.phone
                        : this.props.user.userPhone
                    }
                    onChange={(e) => {
                      this.setState({
                        phone: e.target.value,
                      });
                    }}
                  />
                  <textarea
                    placeholder={this.props.i18n.message}
                    value={this.state.message}
                    onChange={(e) => {
                      this.setState({
                        message: e.target.value,
                      });
                    }}
                  />
                  {this.state.submitLoader ? (
                    <div className="contactLoader">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="mainButton"
                      onClick={() => {


                        if(this.props.user.userName && this.props.user.userPhone && this.props.user.userEmail && this.state.message){
                          this.setState(
                            {
                              submitLoader: true,
                            },
                            () => {
                              this.ContactUs();
                            }
                          );
                        }else{
                          swal({
                            title: this.props.i18n.sorry,
                            text: "الرجاء ملىء جميع الخانات",
                            icon: "warning",
                            buttons: {
                              cancel: "حسناً",
                             
                            },
                            className: "haveEnoughBalance",
                          });
                        }
                       
                      }}
                    >
                      {/* submit button start  */}

                      <div></div>
                      {this.props.i18n.send}
                    </div>
                  )}
                  {/* submit button end  */}
                </div>

                {/* <div className="OtherCountact">
                  <span>{this.props.i18n.OrThrough}</span>

                  <a href={"mailto:" + this.state.emailLib}>
                    {this.state.settingLoader ? null : this.state.emailLib}
                  </a>
                  <a href={"tel:" + this.state.phoneLIb}>
                    {this.state.settingLoader ? null : this.state.phoneLIb}
                  </a>
                </div> */}

                <div className="OtherCountact_mobile">
                  <span>{this.props.i18n.OrThrough}</span>

                  <a href={"mailto:" + this.state.emailLib}>
                    {this.state.settingLoader ? null : this.state.emailLib}
                  </a>
                  <a href={"tel:" + this.state.phoneLIb}>
                    {this.state.settingLoader ? null : this.state.phoneLIb}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* countact us section end  */}
      </div>
    );
  }
  /**  ContactUs api */
  ContactUs() {
    fetch(Global.api_link + "ContactUs", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        name: this.props.user.userName,
        phone: this.props.user.userPhone,
        email: this.props.user.userEmail,
        message: this.state.message,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          if (this.props.user.UID == 69) {
            this.setState(
              {
                submitLoader: false,
                message: "",
                email: "",
                phone: "",
                name: "",
              },
              () => {
                swal({
                  title: this.props.i18n.done,
                  text: "تم ارسال الرسالة",
                  icon: "success",
                  button: {
                    text: this.props.i18n.ok,
                  },
                  // className: "addAccountSwal",
                });
              }
            );
          } else {
            this.setState(
              {
                submitLoader: false,
                message: "",
              },
              () => {
                swal({
                  title: this.props.i18n.done,
                  text: "تم ارسال الرسالة",
                  icon: "success",
                  button: {
                    text: this.props.i18n.ok,
                  },
                  // className: "addAccountSwal",
                });
              }
            );
          }
        }

        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  ContactUs api End */

  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }
  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
