import React, { Component } from "react";
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialMedia: {},
      socialMediaLoader: true,

      setting: {},
      settingLoader: true,
    };
  }

  componentWillMount() {
    this.getUserInfo();
  }

  /**  get user info Page */
  getUserInfo() {
    fetch(Global.api_link + "getuser", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          socialMedia: result.social,
          socialMediaLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**  get user info Page End */

  /**  get setting start */
  getsetting() {
    fetch(Global.api_link + "GetAboutAndPrivacy", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          setting: result.settings[0],
          settingLoader: false,
        });
      })

      .catch((error) => {
        console.error(error);
      });
  }

  /**  get setting  End */

  render() {
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col md={4}>
              <div className="right_side">
                {/* footer logo section start */}
                <div className="footer_logo">
                  <img src="/img/Group740.png" alt="a" />
                  <img src="/img/Group738.png" alt="a" />
                </div>
                {/* footer logo section end */}

                {/* footer social media section start  */}
                {this.state.socialMediaLoader ? null : (
                  <ul className="socialMedia">
                    <li>
                      <a href={this.state.socialMedia.facebook}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>

                    <li>
                      <a href={this.state.socialMedia.twitter}>
                        <i className="fab fa-twitter"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href={this.state.socialMedia.instagram}>
                        <i className="fab fa-instagram"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href={this.state.socialMedia.linkedIn}>
                        <i className="fab fa-linkedin-in"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href={this.state.socialMedia.youtube}>
                        <i className="fab fa-youtube"></i>{" "}
                      </a>
                    </li>
                  </ul>
                )}

                {/* footer social media section end  */}
              </div>
            </Col>

            <Col md={3}>
              <ul className="footer-center">
                <li>
                  <a href="/PublicPage/about">نبذة عن المكتبة</a>
                </li>
                <li>
                  <a href="/PublicPage/privacy_policy ">سياسة الخصوصية</a>
                </li>
                <li>
                  <a href="/PublicPage/conditions "> الشروط والأحكام</a>
                </li>
                <li>
                  <a href="/PublicPage/property_rights">
                    حقوق الملكية الفكرية{" "}
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={5}>
              <div className="footer_left">
                {/* <p>تواصل معنا</p>
                <InputGroup>
                  <FormControl
                    placeholder="اكتب ايميلك"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Prepend>ارسال</InputGroup.Prepend>
                </InputGroup> */}
                <h5>حمل تطبيقنا</h5>
                <img
                  src="/img/android.png"
                  alt="a"
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.redwanpublishar.noonlibrary",
                      "_blank"
                    );
                  }}
                />
                <img
                  src="/img/apple.png"
                  alt="a"
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/%D9%85%D9%83%D8%AA%D8%A8%D8%A9-%D9%86%D9%88%D9%86/id1514349596",
                      "_blank"
                    );
                  }}
                />{" "}
                <img
                  src="/img/hawawi.png"
                  alt="a"
                  onClick={() => {
                    window.open(
                      "https://appgallery.huawei.com/#/app/C103466187",
                      "_blank"
                    );
                  }}
                />{" "}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="sub_footer">
          <Container>
            <div className="sub_footer_contant">
              <span>© جميع الحقوق محفوظة لدى مكتبة نون</span>

              <ul>
                <li>
                  <img src="/img/Mask Group 2.png" />
                </li>
                <li>
                  <img src="/img/visa_logo.png" />
                </li>
                {/* <li>
                  <img src="/img/NoPath.png" />
                </li> */}
              </ul>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
