import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousell from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Book from "../Blocks/Book";
import Skeleton from "react-loading-skeleton";

class Series extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      seriesLoader: true,

      seriesBooks: [],
      seriesBooksLoader: true,
    };
  }

  componentWillMount() {
    this.getSeriesBooks();
  }

  /**  get Series Books */
  getSeriesBooks() {
    fetch(Global.api_link + "getSeriesBooks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ser_id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          seriesBooks: result.series,
          seriesBooksLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get Series Books End */

  render() {
    return (
      <div className="categoryPage">
        {/* books of  category start  */}
        <div className="categories_books">
          <Container>
            <h4 className="title_cate">{this.state.seriesName}</h4>
            <Row style={{ margin: "0px 15px" }}>
              {this.state.seriesBooksLoader
                ? null
                : this.state.seriesBooks.map((item, index) => {
                    return (
                      <Col sm={6} md={4} lg={3} key={index}>
                        <Book bookData={item} />
                      </Col>
                    );
                  })}
            </Row>
          </Container>
        </div>

        {/* books of category end  */}
      </div>
    );
  }

  /**  get Library Sub Categories Series */
  getLibrarySubCategoriesSeries() {
    fetch(Global.api_link + "getLibrarySubCategoriesSeries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cat_id: this.state.headerTab_selected_sub,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          series: result.series,
          seriesLoader: false,
          showSub_categories:
            this.state.SubCategories.length > 0 ? true : false,
          showLastLevelTabs: this.state.SubCategories.length > 0 ? true : false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get Library Sub Categories Series End */
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Series);
