import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Skeleton from "react-loading-skeleton";

class TestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookQuestions: [],
      bookQuestionsLoader: true,
      questionIndex: 0,
      answerSelectedEmpty: true,
      answers: [],
      questionTimer: 60,
    };
  }

  componentWillMount() {
    this.getQuestions();
  }

  /**  get Questions api */
  getQuestions() {
    fetch(Global.api_link + "getQuestions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        book_id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          bookQuestions: result.Questions,
          bookQuestionsLoader: false,
        });

        this.Timer = setInterval(() => {
          if (this.state.questionTimer > 0) {
            this.setState({
              questionTimer: this.state.questionTimer - 1,
            });
          } else {
            {
              if (
                this.state.questionIndex + 2 <
                this.state.bookQuestions.length
              ) {
                var incurrect = this.state.bookQuestions[
                  this.state.questionIndex
                ].Options.find((answer) => answer.is_correct == 0);
                this.setState({
                  questionIndex: this.state.questionIndex + 1,
                  answerSelectedEmpty: true,
                  answerSelectedEmptyError: false,

                  questionIndexChange:
                    this.state.questionIndexChange ==
                    this.state.bookQuestions.length - 1
                      ? this.state.bookQuestions.length
                      : null,

                  answers: [
                    ...this.state.answers,
                    {
                      // question:this.state.questionIndexChange == this.state.bookQuestions.length - 1 ? this.state.questionIndexChange + 1 : this.state.questionIndex + 1,
                      question:
                        this.state.questionIndexChange ==
                        this.state.bookQuestions.length - 1
                          ? this.state.bookQuestions[
                              this.state.questionIndexChange
                            ].id
                          : this.state.bookQuestions[this.state.questionIndex]
                              .id,
                      value: incurrect.id,
                    },
                  ],
                  questionTimer: 60,
                });
              } else {
                var incurrect = this.state.bookQuestions[
                  this.state.questionIndex
                ].Options.find((answer) => answer.is_correct == 0);

                this.setState(
                  {
                    answers: [
                      ...this.state.answers,
                      {
                        question: this.state.bookQuestions[
                          this.state.questionIndex
                        ].id,
                        value: incurrect.id,
                      },
                    ],
                  },
                  () => {
                    this.QuestionsAnswer();
                  }
                );
              }
            }
          }
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get Questions api End */

  render() {
    return (
      <div className="testPage">
        <div className="top_Test_page">
          {this.state.testFinished ? null : (
            <Container>
              <h3>إبدأ الاختبار الان</h3>
              <p>ادوات المساعدة</p>
              <span>
                يتم استخدام هذه الادوات المساعدة للامتحان كاملاً ولا تتجدد مع كل
                سؤال
              </span>
              {/* optins test section start  */}
              <ul>
                <li
                  style={
                    this.state.changeQuestion
                      ? { backgroundColor: "gray" }
                      : null
                  }
                  onClick={() => {
                    if (!this.state.changeQuestion) {
                      this.setState({
                        questionIndexChange:
                          this.state.bookQuestions.length - 1,
                        changeQuestion: true,
                      });
                    }
                  }}
                >
                  تغيير السؤال
                </li>
                <li
                  style={
                    this.state.addTimer ? { backgroundColor: "gray" } : null
                  }
                  onClick={() => {
                    if (!this.state.addTimer) {
                      this.setState({
                        questionTimer: this.state.questionTimer + 60,
                        addTimer: true,
                      });
                    }
                  }}
                >
                  تمديد الوقت 60 ثانية
                </li>
                <li
                  style={
                    this.state.deleteQuestions
                      ? { backgroundColor: "gray" }
                      : null
                  }
                  onClick={() => {
                    this.setState(
                      {
                        ["deleteQuestions" + this.state.questionIndex]: true,
                        deleteQuestions: true,
                      },
                      () => {
                        this.getQuestions();
                      }
                    );
                  }}
                >
                  حذف خيارين
                </li>
              </ul>
              {/* optins test section end  */}
            </Container>
          )}
        </div>

        <Container>
          {/* qustions section start  */}

          {this.state.testFinished ? (
            <div className="finishedTestSection">
              {/* circle images section start  */}
              <img src="/img/FILL.png" alt="k" className="first_path " />
              <img
                src="/img/OUTLINE.png"
                alt="k"
                className={
                  this.state.testFinished
                    ? "second_path second_path_active"
                    : "second_path "
                }
              />
              <img
                src="/img/FILL.png"
                alt="k"
                className={
                  this.state.testFinished
                    ? "third_path third_path_active"
                    : "third_path "
                }
              />
              <img
                src="/img/FILL.png"
                alt="k"
                className={
                  this.state.testFinished
                    ? "fourth_path fourth_path_active"
                    : "fourth_path "
                }
              />
              <img
                src="/img/OUTLINE.png"
                alt="k"
                className={
                  this.state.testFinished
                    ? "fifth_path fifth_path_active"
                    : "fifth_path "
                }
              />
              <img
                src="/img/FILL.png"
                alt="k"
                className={
                  this.state.testFinished
                    ? "sixth_path sixth_path_active"
                    : "sixth_path "
                }
              />
              {/* circle images section end  */}

              <Container>
                {/* the result of test point section start  */}
                {this.state.totalScore == this.state.totalFullMark ? (
                  <div className="finishedTest">
                    <h4>
                      {/* تهانياً لقد احرزت {this.state.totalScore} اجابات صحيح من أصل{" "}
                    {this.state.totalFullMark} */}
                      مبروك علامة كاملة, لقد حصلت على رصيد نقاط ضعف قيمة سعر
                      الكتاب
                    </h4>
                    {/* <p>وبذلك تحصل على {this.state.totalScore}00 نقطة</p> */}
                    <img src="/img/stage_2.png" alt="l" />
                  </div>
                ) : (
                  <div className="finishedTest">
                    <h4>
                      لقد حصلت على {this.state.totalScore} علامات من أصل{" "}
                      {this.state.totalFullMark}
                    </h4>
                    {/* <p>وبذلك تحصل على {this.state.totalScore}00 نقطة</p> */}
                    <img src="/img/stage_2.png" alt="l" />
                  </div>
                )}
                {/* the result of test point section end  */}
              </Container>
            </div>
          ) : this.state.bookQuestionsLoader ? (
            <div>
              <Skeleton height={300} />
            </div>
          ) : (
            <div className="test_qus">
              {/* count of lessions start  */}
              <p>
                {this.state.bookQuestions.length - 1} /
                {this.state.questionIndex + 1}
              </p>
              {/* count of lessions start  */}

              <div className="cart_qus">
                <div className="qus_time">
                  {/* the qustion start  */}
                  <span>
                    {this.state.questionIndexChange ==
                    this.state.bookQuestions.length - 1
                      ? this.state.bookQuestions[this.state.questionIndexChange].name
                      : this.state.bookQuestions[this.state.questionIndex].name}
                  </span>
                  {/* the qustion end  */}

                  {/* count time of qustion start  */}

                  <span>{this.state.questionTimer} : 00</span>
                  {/* count time of qustion start  */}
                </div>
                {/* options of answers start  */}
                <ul>
                  {this.state.questionIndexChange ==
                  this.state.bookQuestions.length - 1
                    ? //  delete two  question section start

                      this.state.bookQuestions[
                        this.state.questionIndexChange
                      ].Options.map((item, index) => {
                        return this.state[
                          "deleteQuestions" + this.state.questionIndex
                        ] ? (
                          item.is_correct == 1 ? (
                            <>
                              <li>
                                <p
                                  onClick={() =>
                                    this.setState({
                                      answerSelected:
                                        index == 0
                                          ? this.state.bookQuestions[
                                              this.state.questionIndexChange
                                            ].Options[1].id
                                          : this.state.bookQuestions[
                                              this.state.questionIndexChange
                                            ].Options[0].id,

                                      answerSelectedEmpty: false,
                                      answerSelectedEmptyError: false,
                                      value:
                                        index == 0
                                          ? this.state.bookQuestions[
                                              this.state.questionIndexChange
                                            ].Options[1].id
                                          : this.state.bookQuestions[
                                              this.state.questionIndexChange
                                            ].Options[0].id,
                                    })
                                  }
                                  style={
                                    this.state.answerSelectedEmptyError
                                      ? { borderColor: "red" }
                                      : null
                                  }
                                  className={
                                    this.state.answerSelected ==
                                    (index == 0
                                      ? this.state.bookQuestions[
                                          this.state.questionIndexChange
                                        ].Options[1].id
                                      : this.state.bookQuestions[
                                          this.state.questionIndexChange
                                        ].Options[0].id)
                                      ? "checkedAnswer"
                                      : ""
                                  }
                                >
                                  {this.state.answerSelected ==
                                  (index == 0
                                    ? this.state.bookQuestions[
                                        this.state.questionIndexChange
                                      ].Options[0].id
                                    : this.state.bookQuestions[
                                        this.state.questionIndexChange
                                      ].Options[0].id) ? (
                                    <i className="fa fa-check"></i>
                                  ) : null}
                                </p>

                                <span
                                  onClick={() =>
                                    this.setState({
                                      answerSelected:
                                        index == 0
                                          ? this.state.bookQuestions[
                                              this.state.questionIndexChange
                                            ].Options[1].id
                                          : this.state.bookQuestions[
                                              this.state.questionIndexChange
                                            ].Options[0].id,
                                      answerSelectedEmpty: false,
                                      answerSelectedEmptyError: false,
                                      value:
                                        index == 0
                                          ? this.state.bookQuestions[
                                              this.state.questionIndexChange
                                            ].Options[1].id
                                          : this.state.bookQuestions[
                                              this.state.questionIndexChange
                                            ].Options[0].id,
                                    })
                                  }
                                >
                                  {index == 0
                                    ? this.state.bookQuestions[
                                        this.state.questionIndexChange
                                      ].Options[1].value
                                    : this.state.bookQuestions[
                                        this.state.questionIndexChange
                                      ].Options[0].value}{" "}
                                </span>
                              </li>

                              <li key={index}>
                                <p
                                  onClick={() =>
                                    this.setState({
                                      answerSelected: item.id,
                                      answerSelectedEmpty: false,
                                      answerSelectedEmptyError: false,
                                      value: item.id,
                                    })
                                  }
                                  style={
                                    this.state.answerSelectedEmptyError
                                      ? { borderColor: "red" }
                                      : null
                                  }
                                  className={
                                    this.state.answerSelected == item.id
                                      ? "checkedAnswer"
                                      : ""
                                  }
                                >
                                  {this.state.answerSelected == item.id ? (
                                    <i className="fa fa-check"></i>
                                  ) : null}
                                </p>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      answerSelected: item.id,
                                      answerSelectedEmpty: false,
                                      answerSelectedEmptyError: false,
                                      value: item.id,
                                    })
                                  }
                                >
                                  {item.value}{" "}
                                </span>
                              </li>
                            </>
                          ) : null
                        ) : (
                          <li key={index}>
                            <p
                              onClick={() =>
                                this.setState({
                                  answerSelected: item.id,
                                  answerSelectedEmpty: false,
                                  answerSelectedEmptyError: false,
                                  value: item.id,
                                })
                              }
                              style={
                                this.state.answerSelectedEmptyError
                                  ? { borderColor: "red" }
                                  : null
                              }
                              className={
                                this.state.answerSelected == item.id
                                  ? "checkedAnswer"
                                  : ""
                              }
                            >
                              {this.state.answerSelected == item.id ? (
                                <i className="fa fa-check"></i>
                              ) : null}
                            </p>
                            <span
                              onClick={() =>
                                this.setState({
                                  answerSelected: item.id,
                                  answerSelectedEmpty: false,
                                  answerSelectedEmptyError: false,
                                  value: item.id,
                                })
                              }
                            >
                              {item.value}
                            </span>
                          </li>
                        );
                      })
                    : // normal question options  without change question start

                      this.state.bookQuestions[
                        this.state.questionIndex
                      ].Options.map((item, index) => {
                        return this.state[
                          "deleteQuestions" + this.state.questionIndex
                        ] ? (
                          item.is_correct == 1 ? (
                            <>
                              <li>
                                <p
                                  onClick={() =>
                                    this.setState({
                                      answerSelected:
                                        index == 0
                                          ? this.state.bookQuestions[
                                              this.state.questionIndex
                                            ].Options[1].id
                                          : this.state.bookQuestions[
                                              this.state.questionIndex
                                            ].Options[0].id,

                                      answerSelectedEmpty: false,
                                      answerSelectedEmptyError: false,

                                      value:
                                        index == 0
                                          ? this.state.bookQuestions[
                                              this.state.questionIndex
                                            ].Options[1].id
                                          : this.state.bookQuestions[
                                              this.state.questionIndex
                                            ].Options[0].id,
                                    })
                                  }
                                  style={
                                    this.state.answerSelectedEmptyError
                                      ? { borderColor: "red" }
                                      : null
                                  }
                                  className={
                                    this.state.answerSelected ==
                                    (index == 0
                                      ? this.state.bookQuestions[
                                          this.state.questionIndex
                                        ].Options[1].id
                                      : this.state.bookQuestions[
                                          this.state.questionIndex
                                        ].Options[0].id)
                                      ? "checkedAnswer"
                                      : ""
                                  }
                                >
                                  {this.state.answerSelected ==
                                  (index == 0
                                    ? this.state.bookQuestions[
                                        this.state.questionIndex
                                      ].Options[0].id
                                    : this.state.bookQuestions[
                                        this.state.questionIndex
                                      ].Options[0].id) ? (
                                    <i className="fa fa-check"></i>
                                  ) : null}
                                </p>

                                <span
                                  onClick={() =>
                                    this.setState({
                                      answerSelected:
                                        index == 0
                                          ? this.state.bookQuestions[
                                              this.state.questionIndex
                                            ].Options[1].id
                                          : this.state.bookQuestions[
                                              this.state.questionIndex
                                            ].Options[0].id,
                                      answerSelectedEmpty: false,
                                      answerSelectedEmptyError: false,
                                      value:
                                        index == 0
                                          ? this.state.bookQuestions[
                                              this.state.questionIndex
                                            ].Options[1].id
                                          : this.state.bookQuestions[
                                              this.state.questionIndex
                                            ].Options[0].id,
                                    })
                                  }
                                >
                                  {index == 0
                                    ? this.state.bookQuestions[
                                        this.state.questionIndex
                                      ].Options[1].value
                                    : this.state.bookQuestions[
                                        this.state.questionIndex
                                      ].Options[0].value}{" "}
                                </span>
                              </li>

                              <li key={index}>
                                <p
                                  onClick={() =>
                                    this.setState({
                                      answerSelected: item.id,
                                      answerSelectedEmpty: false,
                                      answerSelectedEmptyError: false,
                                      value: item.id,
                                    })
                                  }
                                  style={
                                    this.state.answerSelectedEmptyError
                                      ? { borderColor: "red" }
                                      : null
                                  }
                                  className={
                                    this.state.answerSelected == item.id
                                      ? "checkedAnswer"
                                      : ""
                                  }
                                >
                                  {this.state.answerSelected == item.id ? (
                                    <i className="fa fa-check"></i>
                                  ) : null}
                                </p>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      answerSelected: item.id,
                                      answerSelectedEmpty: false,
                                      answerSelectedEmptyError: false,
                                      value: item.id,
                                    })
                                  }
                                >
                                  {item.value}
                                </span>
                              </li>
                            </>
                          ) : null
                        ) : (
                          <li key={index}>
                            <p
                              onClick={() =>
                                this.setState({
                                  answerSelected: item.id,
                                  answerSelectedEmpty: false,
                                  answerSelectedEmptyError: false,
                                  value: item.id,
                                })
                              }
                              style={
                                this.state.answerSelectedEmptyError
                                  ? { borderColor: "red" }
                                  : null
                              }
                              className={
                                this.state.answerSelected == item.id
                                  ? "checkedAnswer"
                                  : ""
                              }
                            >
                              {this.state.answerSelected == item.id ? (
                                <i className="fa fa-check"></i>
                              ) : null}
                            </p>
                            <span
                              onClick={() =>
                                this.setState({
                                  answerSelected: item.id,
                                  answerSelectedEmpty: false,
                                  answerSelectedEmptyError: false,
                                  value: item.id,
                                })
                              }
                            >
                              {item.value}
                            </span>
                          </li>
                        );
                      })}
                </ul>
                {/* options of answers end  */}

                {/* submit answer start  */}
                <div className="submitAnswer">
                  <button
                    onClick={() => {
                      if (this.state.answerSelectedEmpty) {
                        this.setState({
                          answerSelectedEmptyError: true,
                        });
                      } else {
                        if (
                          this.state.questionIndex + 2 <
                          this.state.bookQuestions.length
                        ) {
                          this.setState({
                            questionIndex: this.state.questionIndex + 1,
                            answerSelectedEmpty: true,
                            answerSelectedEmptyError: false,
                            questionIndexChange:
                              this.state.questionIndexChange ==
                              this.state.bookQuestions.length - 1
                                ? this.state.bookQuestions.length
                                : null,
                            answers: [
                              ...this.state.answers,
                              {
                                question:
                                  this.state.questionIndexChange ==
                                  this.state.bookQuestions.length - 1
                                    ? this.state.bookQuestions[
                                        this.state.questionIndexChange
                                      ].id
                                    : this.state.bookQuestions[
                                        this.state.questionIndex
                                      ].id,
                                value: this.state.value,
                              },
                            ],
                            questionTimer: 60,
                          });
                        } else {
                          this.setState(
                            {
                              answers: [
                                ...this.state.answers,
                                {
                                  question: this.state.bookQuestions[
                                    this.state.questionIndex
                                  ].id,
                                  value: this.state.value,
                                },
                              ],
                            },
                            () => {
                              this.QuestionsAnswer();
                            }
                          );
                        }
                      }
                    }}
                  >
                    السؤال التالي
                  </button>
                </div>

                {/* submit answer end  */}
              </div>
            </div>
          )}

          {/* qustions section start  */}

          {/* the Award section satrt  */}
          {this.state.testFinished ? null : (
            <div className="awardImg">
              <img src="/img/stage_2.png" alt="l" />
            </div>
          )}

          {/* the Award section satrt  */}
        </Container>
      </div>
    );
  }

  /**  Questions Answer Page */
  QuestionsAnswer() {
    clearInterval(this.Timer);

    fetch(Global.api_link + "QuestionsAnswer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + Global.token,
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
        book_id: this.props.match.params.id,
        answers: this.state.answers,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          totalScore: result.data.totalScore,
          totalFullMark: result.data.totalFullMark,
          testFinished: true,
        });
        setTimeout(() => {
          window.location.href = "/bookDetails/" + this.props.match.params.id;
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  Questions Answer Page End */
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
