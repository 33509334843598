import "./App.css";
import Header from "./Blocks/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Pages/Home";
import Footer from "./Blocks/Footer";
import { BrowserRouter, Route } from "react-router-dom";
import Login from "./Pages/Login";
import HisFavLib from "./Pages/HisFavLib";
import Categories from "./Pages/Categories";
import BookDetails from "./Pages/BookDetails";
import Profile from "./Pages/Profile";
import TestPage from "./Pages/TestPage";
import Register from "./Pages/Register";
import Search from "./Pages/Search";
import PublicPage from "./Pages/PublicPage";
import Series from "./Pages/Series";

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Route path="/" component={Home} exact />
        <Route path="/login" component={Login} />
        <Route path="/Register" component={Register} />
        <Route path="/favorities/:key" component={HisFavLib} />
        <Route path="/Categories/:id" component={Categories} />
        <Route path="/BookDetails/:id" component={BookDetails} />
        <Route path="/TestPage/:id" component={TestPage} />
        <Route path="/profile/:key" component={Profile} />
        <Route path="/Search/:key" component={Search} />
        <Route path="/PublicPage/:key" component={PublicPage} />
        <Route path="/Series/:id" component={Series} />
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
