import { LANGUAGE_SET } from "../actionTypes/index";
import { LANGUAGE_REQUEST } from "../actionTypes/index";

export const languageRequest = () => {
  return {
    type: LANGUAGE_REQUEST,
  };
};

export function setLanguage(lang) {
  return {
    type: LANGUAGE_SET,
    payload: lang,
  };
}
