import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousell from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Book from "../Blocks/Book";
import Skeleton from "react-loading-skeleton";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1200, min: 768 },
          items: 3,
        },
        mobile: {
          breakpoint: { max: 768, min: 576 },
          items: 2,
        },
        Sm_mobile: {
          breakpoint: { max: 576, min: 0 },
          items: 1,
        },
      },
      headerTab_selected: this.props.match.params.id,
      headerTab_selected_sub: null,
      showLastLevelTabs: true,
      showMain_categories: true,
      showSub_categories: true,
      Categories: [],
      CategoriesLoader: true,

      SubCategories: [],
      SubCategoriesLoader: true,

      series: [],
      seriesLoader: true,

      seriesBooks: [],
      seriesBooksLoader: true,
    };
  }

  componentWillMount() {
    this.getLibraryCategories();
  }

  /**  get Library Categories Page */
  getLibraryCategories() {
    fetch(Global.api_link + "getLibraryCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        library_id: 1,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // if (this.state.headerTab_selected == null) {
        //   this.setState(
        //     {
        //       Categories: result.Categories,
        //       CategoriesLoader: false,
        //       headerTab_selected: result.Categories[0].id,
        //     },
        //     () => {
        //       this.getLibrarySubCategories();
        //     }
        //   );
        // } else {
        this.setState(
          {
            Categories: result.Categories,
            CategoriesLoader: false,
          },
          () => {
            this.getLibrarySubCategories();
          }
        );
        // }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get Library Categories Page End */

  /**  get sub Categories Page */
  getLibrarySubCategories() {
    fetch(Global.api_link + "getLibrarySubCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        library_id: this.state.headerTab_selected,
        parent_id: 1,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // if (this.state.headerTab_selected_sub == null) {
        this.setState(
          {
            SubCategories: result.subCategory,
            SubCategoriesLoader: false,
            headerTab_selected_sub: result.subCategory[0].id,
          },
          () => {
            this.getLibrarySubCategoriesSeries();
          }
        );
        // } else {
        //   this.setState(
        //     {
        //       SubCategories: result.subCategory,
        //       SubCategoriesLoader: false,
        //     },
        //     () => {
        //       this.getLibrarySubCategoriesSeries();
        //     }
        //   );
        // }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get sub Categories Page End */

  render() {
    return (
      <div className="categoryPage">
        {/* header tabs section start  */}

        <div className="headerTabs">
          <Container>
            <ul>
              {this.state.CategoriesLoader
                ? // <div>
                  //   <Skeleton height={150} />
                  // </div>
                  null
                : this.state.Categories.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          this.setState(
                            {
                              headerTab_selected: item.id,
                              showLastLevel: false,
                              showMain_categories: true,
                              seriesLoader: true,
                              // showSub_categories: false,
                              // showLastLevelTabs: false,
                            },
                            () => {
                              this.getLibrarySubCategories();
                            }
                          );
                        }}
                        className={
                          this.state.headerTab_selected == item.id
                            ? "bg_dark"
                            : ""
                        }
                      >
                        {item.name}
                      </li>
                    );
                  })}
            </ul>
          </Container>
        </div>
        {/* header tabs section end  */}
        {/* header sub tabs section start  */}
        <div className="headerTabs headerTabs_sub">
          <Container>
            <ul>
              {this.state.SubCategoriesLoader
                ? // <div>
                  //   <Skeleton height={150} />
                  // </div>
                  null
                : this.state.SubCategories.map((item, index) => {
                    return (
                      <li
                        onClick={() => {
                          this.setState(
                            {
                              headerTab_selected_sub: item.id,
                              showMain_categories: false,
                              showLastLevel: false,
                              showSub_categories: true,
                              showLastLevelTabs: true,
                              last_headerTab_selected: null,
                              seeMoreCategories: false,
                              seriesLoader: true,
                            },
                            () => {
                              this.getLibrarySubCategoriesSeries();
                            }
                          );
                        }}
                        className={
                          this.state.headerTab_selected_sub == item.id
                            ? "bg_DarkGreen"
                            : ""
                        }
                      >
                        {item.name}
                      </li>
                    );
                  })}
            </ul>
          </Container>
        </div>
        {/* header sub tabs section start  */}

        {/* last  header sub tabs section start  */}
        {this.state.showLastLevelTabs ? (
          <div
            className={
              this.state.seeMoreCategories
                ? "lastLevel_headerTabs seeMoreCategoriesHeader"
                : "lastLevel_headerTabs  "
            }

            // style={
            //   this.state.seeMoreCategories
            //     ? { height: "auto", overflow: "auto" }
            //     : { height: "49px", overflow: "hidden" }
            // }
          >
            <Container>
              <ul>
                {this.state.seriesLoader
                  ? // <div>
                    //   <Skeleton height={150} />
                    // </div>
                    null
                  : this.state.series.map((item, index) => {
                      return (
                        <li
                          onClick={() => {
                            this.setState(
                              {
                                last_headerTab_selected: item.id,
                                showSub_categories: false,
                                showMain_categories: false,
                                showLastLevel: true,
                                seriesName: item.name,
                              },
                              () => {
                                this.getSeriesBooks();
                              }
                            );
                          }}
                          className={
                            this.state.last_headerTab_selected == item.id
                              ? "lastLevel_headerTabs_Selected"
                              : ""
                          }
                        >
                          {" "}
                          <span>{item.name}</span>
                        </li>
                      );
                    })}
              </ul>
            </Container>
          </div>
        ) : null}

        <Container>
          <div className="seeMoreCotegories">
            <span
              onClick={() => {
                this.setState({
                  seeMoreCategories: !this.state.seeMoreCategories,
                });
              }}
            >
              {this.state.seeMoreCategories ? "اقل" : "المزيد"}
            </span>
          </div>
        </Container>
        {/* last header sub tabs section end  */}

        {/* sub category carousels start  */}
        {/* {this.state.showMain_categories
          ? this.state.seriesLoader
            ? // <div>
              //   <Skeleton height={150} />
              // </div>
              null
            : this.state.series.map((item, index) => {
                return (
                  <div className="latestVersion">
                    <div className="carousel_title">
                      <Container>
                        <div className="title_subCate">
                          <h5>{item.name}</h5>
                          <div
                            onClick={() => {
                              this.setState({
                                headerTab_selected_sub: item.id,
                                showMain_categories: false,
                                showLastLevel: false,
                                showSub_categories: true,
                                showLastLevelTabs: true,
                                last_headerTab_selected: null,
                              });
                            }}
                          >
                            <span>المزيد</span>
                            <img src="/img/left.png" alt="k" />
                          </div>
                        </div>
                      </Container>
                    </div>
                    <Container>
                      <Carousell
                        responsive={this.state.responsive}
                        showDots={true}
                        infinite={true}
                        showDots={false}
                        // className={this.state.categories.length < 12 ? "rtl" : ""}
                      >
                        {item.books.map((book, bookIndex) => {
                          return <Book key={bookIndex} bookData={book} />;
                        })}
                        {/* <Book />


<Book />

<Book />

<Book />

<Book />

<Book /> 
                      </Carousell>
                    </Container>
                  </div>
                );
              })
          : null} */}

        {/* sub category carousels end  */}

        {/*## last sub category carousels start  */}
        {this.state.showSub_categories ? (
          this.state.seriesLoader ? (
            <div style={{ margin: "30px 0" }}>
              <Skeleton height={300} />
            </div>
          ) : (
            // null
            this.state.series.map((item, index) => {
              return (
                <div className="latestVersion">
                  <div className="carousel_title">
                    <Container>
                      <div className="title_subCate">
                        <h5>{item.name}</h5>
                        <div
                          onClick={() => {
                            this.setState(
                              {
                                last_headerTab_selected: item.id,
                                showSub_categories: false,
                                showMain_categories: false,
                                showLastLevel: true,
                                seriesName: item.name,
                              },
                              () => {
                                this.getSeriesBooks();
                              }
                            );
                          }}
                        >
                          {item.books.length < 4 ? null : (
                            <>
                              <span>المزيد</span>
                              <img src="/img/left.png" alt="k" />
                            </>
                          )}
                        </div>
                      </div>
                    </Container>
                  </div>
                  <Container>
                    <Carousell
                      responsive={this.state.responsive}
                      showDots={true}
                      infinite={true}
                      showDots={false}
                      className={item.books.length < 4 ? "rtl" : ""}
                    >
                      {item.books.map((book, bookIndex) => {
                        return <Book key={bookIndex} bookData={book} />;
                      })}
                    </Carousell>
                  </Container>
                </div>
              );
            })
          )
        ) : null}

        {/*## last sub category carousels start  */}

        {/* books of  category start  */}
        {this.state.showLastLevel ? (
          <div className="categories_books">
            <Container>
              <h4 className="title_cate">{this.state.seriesName}</h4>
              <Row style={{ margin: "0px 15px" }}>
                {this.state.seriesBooksLoader ? (
                  <>
                    <Col sm={6} md={4} lg={3}>
                      <Skeleton height={250} />
                    </Col>

                    <Col sm={6} md={4} lg={3}>
                      <Skeleton height={250} />
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                      <Skeleton height={250} />
                    </Col>
                  </>
                ) : (
                  this.state.seriesBooks.map((item, index) => {
                    return (
                      <Col sm={6} md={4} lg={3} key={index}>
                        <Book bookData={item} />
                      </Col>
                    );
                  })
                )}
              </Row>
            </Container>
          </div>
        ) : null}

        {/* books of category end  */}
      </div>
    );
  }

  /**  get Library Sub Categories Series */
  getLibrarySubCategoriesSeries() {
    fetch(Global.api_link + "getLibrarySubCategoriesSeries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cat_id: this.state.headerTab_selected_sub,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          series: result.series,
          seriesLoader: false,
          showSub_categories:
            this.state.SubCategories.length > 0 ? true : false,
          showLastLevelTabs: this.state.SubCategories.length > 0 ? true : false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get Library Sub Categories Series End */

  /**  get Series Books */
  getSeriesBooks() {
    fetch(Global.api_link + "getSeriesBooks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ser_id: this.state.last_headerTab_selected,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          seriesBooks: result.series,
          seriesBooksLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  get Series Books End */
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
