import React, { Component } from "react";
import {
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
  Dropdown,
} from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import swal from "sweetalert";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginForm: 2,
      Password: "",
      email: "",
      regEmail: "",
      phone: "",
      regPassword: "",
      countries: [],
      countryCode: "+1",
    };
  }

  componentWillMount() {
    this.getCountries();
  }

  /** get Countries Web */
  getCountries() {
    fetch("/c.json", {
      method: "GET",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        function compare(a, b) {
          if (a.dialling_code < b.dialling_code) {
            return -1;
          }
          if (a.dialling_code > b.dialling_code) {
            return 1;
          }
          return 0;
        }

        this.setState({
          countries: result.countryCodes
            .sort(compare)
            .filter(
              (v, i, a) =>
                a.findIndex((t) => t.dialling_code === v.dialling_code) === i
            ),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /** get Countries Web End */

  render() {
    function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    return (
      <div className="loginPage">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="login_form">
                {/* img backgraound  form start  */}
                <img src="/img/page.png" alt="k" className="form_bg" />
                {/* img backgraound  form end  */}
                {/* form tabs section start  */}
                <div className="loginTabs">
                  <Row className="no-margin">
                    <Col xs={6} className="no-padd">
                      <span
                        className={
                          this.state.loginForm == 1 ? "loginSelected" : ""
                        }
                        onClick={() => {
                          window.location.href = "/login";
                        }}
                      >
                        تسجيل دخول
                      </span>
                    </Col>
                    <Col xs={6} className="no-padd">
                      <span
                        className={
                          this.state.loginForm == 2 ? "loginSelected" : ""
                        }
                        onClick={() => {
                          window.location.href = "/register";
                        }}
                      >
                        تسجيل
                      </span>
                    </Col>
                  </Row>
                </div>
                {/* form tabs section end  */}

                {/* form  section start  */}

                <div className="formInputs">
                  <div className="">
                    <Row className="">
                      <Col sm={6}>
                        <input
                          className={
                            this.state.firstNameEmpty ? "border_red" : ""
                          }
                          type="text"
                          placeholder={this.props.i18n.firstName}
                          onChange={(e) => {
                            this.setState({
                              firstName: e.target.value,
                              firstNameEmpty: false,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <input
                          className={
                            this.state.lastNameEmpty ? "border_red" : ""
                          }
                          type="text"
                          placeholder={this.props.i18n.lastName}
                          onChange={(e) => {
                            this.setState({
                              lastName: e.target.value,
                              lastNameEmpty: false,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <input
                    type="text"
                    placeholder={this.props.i18n.email}
                    className={this.state.regEmailEmpty ? "border_red" : ""}
                    onChange={(e) => {
                      this.setState(
                        {
                          regEmail: e.target.value,
                          regEmailEmpty: false,
                        },
                        () => {
                          this.emailValidate();
                        }
                      );
                    }}
                    value={this.state.regEmail.trim()}
                  />
                  {this.state.emailError && this.state.regEmail != "" ? (
                    <span className="note-pass">الايميل غير صحيح</span>
                  ) : null}

                  {/* phone input start */}
                  {/* <input
                    type="text"
                    maxLength={10}
                    className={this.state.phoneEmpty ? "border_red" : ""}
                    placeholder={this.props.i18n.phone}
                    onChange={(e) => {
                      if (
                        isNumber(e.target.value) ||
                        e.target.value.length == 0
                      ) {
                        this.setState({
                          phone: e.target.value,
                          phoneEmpty: false,
                        });
                      }
                    }}
                    value={this.state.phone.trim()}
                  />
             */}

                  <InputGroup
                    className={this.state.phoneEmpty ? "border_red" : ""}
                  >
                    <FormControl
                      maxLength={14}
                      placeholder={this.props.i18n.phone}
                      onChange={(e) => {
                        if (
                          isNumber(e.target.value) ||
                          e.target.value.length == 0
                        ) {
                          this.setState({
                            phone: e.target.value,
                            phoneEmpty: false,
                          });
                        }
                      }}
                      value={this.state.phone.trim()}
                    />
                    <InputGroup.Append>
                      <select
                        onChange={(e) => {
                          this.setState({
                            countryCode: e.target.value,
                          });
                        }}
                      >
                        {this.state.countries.map((item, index) => {
                          return (
                            <option value={item.dialling_code} key={index}>
                              {item.dialling_code}
                            </option>
                          );
                        })}
                      </select>{" "}
                    </InputGroup.Append>
                  </InputGroup>

                  {this.state.phone.length > 14 ? (
                    <span className="note-pass">
                      يجب ان لا يكون الرقم اكثر من 10 خانات
                    </span>
                  ) : null}
                  {/* phone input end  */}

                  <InputGroup
                    className={
                      this.state.regPasswordEmpty ? "border_red mb-3" : "mb-3"
                    }
                  >
                    <FormControl
                      type={this.state.showPass ? "text" : "password"}
                      aria-describedby="basic-addon1"
                      placeholder={this.props.i18n.password}
                      onChange={(e) => {
                        this.setState({
                          regPassword: e.target.value,
                          regPasswordEmpty: false,
                        });
                      }}
                      value={this.state.regPassword.trim()}
                    />
                    <InputGroup.Append>
                      {this.state.showPass ? (
                        <img
                          src="/img/Group 731.png"
                          className="reg-inp-img"
                          onClick={() =>
                            this.setState({
                              showPass: !this.state.showPass,
                            })
                          }
                        />
                      ) : (
                        <img
                          src="/img/eye_close@noon.png"
                          className="pass-view"
                          onClick={() =>
                            this.setState({
                              showPass: !this.state.showPass,
                            })
                          }
                        />
                      )}
                    </InputGroup.Append>
                  </InputGroup>
                  {this.state.regPassword.length < 6 &&
                  this.state.regPassword != "" ? (
                    <span className="note-pass">
                      {this.props.i18n.mustBeMore6}
                    </span>
                  ) : null}
                </div>
                {/* form  section end  */}

                {/* social buttons  section start  */}
                <div className="socilaLogin_buttons">
                  <Row className="no-margin">
                    <Col xs={6} className="no-padd">
                      <div className="socilaLogin">
                        <p>تسجيل عن طريق </p>
                        <FacebookLogin
                          appId="3487964654610027"
                          autoLoad={false}
                          fields="id,name,email,picture"
                          callback={this.responseFacebook}
                          onClick={() => this.setState({ isPageLoad: true })}
                          cssclassName="facebookLgin"
                          isMobile={false}
                          icon="fa-facebook"
                          textButton={null}
                          className="facbookLogin"
                        />
                        <GoogleLogin
                          clientId="233832999807-pcv295f9mmj0k2c9addbpdi4dakdno9r.apps.googleusercontent.com"
                          buttonText={null}
                          onSuccess={this.responseGoogle}
                          onFailure={(error) => {
                            console.log(error);
                          }}
                          onClick={() => this.setState({ isPageLoad: true })}
                          cookiePolicy={"single_host_origin"}
                          className="GoogleLoginIcon"
                          // icon="fa-facebook"
                        />
                      </div>
                    </Col>
                    <Col xs={6} className="no-padd margin-top-auto">
                      {/* {this.state.loginForm == 1 ? ( */}
                      {this.state.submitLoader ? (
                        <div className="loginLoader">
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="mainButton"
                          onClick={() => {
                            this.register();
                          }}
                        >
                          {/* submit button start  */}
                          <div></div> انشاء الحساب
                          {/* submit button end  */}
                        </div>
                      )}
                      {/* // ) : (
                      //   <div className="mainButton">
                      //     <div></div> انشاء الحساب
                      //   </div>
                      // )} */}
                    </Col>
                  </Row>
                </div>
                {/* social buttons  section end  */}
              </div>
            </Col>

            {/* main login img start  */}
            <Col md={6} className="margin-top-auto d_none_under992">
              <div className="loginMainImg">
                <img src="/img/Group 1052.png" alt="l" />
              </div>
            </Col>
            {/* main login img end  */}
          </Row>
        </Container>
      </div>
    );
  }

  //  register api  start
  register() {
    if (
      this.state.regEmail == "" ||
      this.state.regEmail == null ||
      this.state.firstName == "" ||
      this.state.firstName == null ||
      this.state.lastName == "" ||
      this.state.lastName == null ||
      this.state.phone == "" ||
      this.state.phone == null ||
      this.state.regPassword == "" ||
      this.state.regPassword == null ||
      this.state.emailError ||
      this.state.regPassword.length < 6
    ) {
      if (this.state.regEmail == "" || this.state.regEmail == null) {
        this.setState({
          regEmailEmpty: true,
        });
      }
      if (this.state.regPassword == "" || this.state.regPassword == null) {
        this.setState({
          regPasswordEmpty: true,
        });
      }
      if (this.state.phone == "" || this.state.phone == null) {
        this.setState({
          phoneEmpty: true,
        });
      }
      if (this.state.firstName == "" || this.state.firstName == null) {
        this.setState({
          firstNameEmpty: true,
        });
      }
      if (this.state.lastName == "" || this.state.lastName == null) {
        this.setState({
          lastNameEmpty: true,
        });
      }
    } else {
      this.setState(
        {
          submitLoader: true,
        },
        () => {
          fetch(Global.api_link + "userSignup", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: this.state.regEmail,
              password: this.state.regPassword,
              name: this.state.firstName + " " + this.state.lastName,
              phone: this.state.countryCode + this.state.phone,
              device_id: Math.random(),
            }),
          })
            .then((response) => response.json())
            .then(async (result) => {
              this.setState(
                {
                  submitLoader: false,
                },
                async () => {
                  if (result.status == 2) {
                    swal({
                      title: this.props.i18n.sorry,
                      text: "البريد الالكتروني مستخدم من قبل",
                      icon: "warning",
                      button: {
                        text: this.props.i18n.ok,
                      },
                      // className: "addAccountSwal",
                    });
                  } else if (result.status == 0) {
                    swal({
                      title: this.props.i18n.sorry,
                      text: result.error,
                      icon: "warning",
                      button: {
                        text: this.props.i18n.ok,
                      },
                      // className: "addAccountSwal",
                    });
                  } else {
                    let { actions } = this.props;
                    await actions.setUser({
                      ...this.props.user,
                      UID: result.data,
                      // userName: result.data.user.name,
                      // access_token: result.data.access_token,
                    });

                    window.location.href = "/";
                  }
                }
              );
            })
            .catch((error) => {
              console.error(error);
            });
        }
      );
    }
  }
  //  register api  end

  //// email validation function
  validateEmailRegular(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  emailValidate() {
    var value = this.state.regEmail;
    if (!this.validateEmailRegular(value)) {
      this.setState({
        emailError: true,
        emailValidate: value + this.props.i18n.notValidEmail,
      });
    } else {
      this.setState({
        emailError: false,
        // emailValidate: this.props.i18n.insertEmail,
      });
    }
  }
  //// end email validation function

  responseFacebook = (response) => {
    //console.log(response);
    if (response.id) {
      fetch(Global.api_link + "FacebookLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          email: response.email ? response.email : "",
          name: response.name ? response.name : "",
          image: response.image ? response.image : "",
          facebook_id: response.id ? response.id : "",
        }),
      })
        .then((response) => response.json())
        .then(async (result) => {
          let { actions } = this.props;
          await actions.setUser({
            ...this.props.user,
            UID: result.data,
            // userName: result.data.user.name,
            // access_token: result.data.access_token,
          });

          window.location.href = "/";
        })
        .catch((error) => {
          //console.error(error);
        });
    }
  };

  responseGoogle = (response) => {
    //console.error(response);
    if (response.googleId) {
      fetch(Global.api_link + "GoogleLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          email: response.profileObj.email ? response.profileObj.email : "",
          image: response.profileObj.imageUrl
            ? response.profileObj.imageUrl
            : "",
          name: response.profileObj.givenName
            ? response.profileObj.givenName +
              " " +
              response.profileObj.familyName
            : "",
          google_id: response.profileObj.googleId
            ? response.profileObj.googleId
            : "",
        }),
      })
        .then((response) => response.json())
        .then(async (result) => {
          let { actions } = this.props;
          await actions.setUser({
            ...this.props.user,
            UID: result.data,
            // userName: result.data.user.name,
            // access_token: result.data.access_token,
          });

          window.location.href = "/";
        })
        .catch((error) => {
          //console.error(error);
        });
    }
  };
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
