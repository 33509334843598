import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Book from "../Blocks/Book";
import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBooks: [],
      searchBooksLoader: true,
    };
  }

  componentWillMount() {
    this.getSearch();
  }

  /**  getSearch Page */
  getSearch() {
    fetch(Global.api_link + "getSearch", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: this.props.match.params.key,
        // user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          searchBooks: result.Search,
          searchBooksLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  getSearch Page End */

  render() {
    return (
      <div className="searchPage">
        <Container>
          <div className="title">
            <h2>نتائج البحث عن : {this.props.match.params.key}</h2>
          </div>
          <Row>
            {this.state.searchBooksLoader ? (
              <>
                <Col sm={6} md={4} lg={3}>
                  <Skeleton height={250} />
                </Col>

                <Col sm={6} md={4} lg={3}>
                  <Skeleton height={250} />
                </Col>

                <Col sm={6} md={4} lg={3}>
                  <Skeleton height={250} />
                </Col>
              </>
            ) : this.state.searchBooks.length < 1 ? (
              <div className="noResult">
                <img src="/img/not_found.png" alt="l" />
                <h3>لا يوجد نتائج</h3>
              </div>
            ) : (
              this.state.searchBooks.map((item, index) => {
                return (
                  <Col sm={6} md={4} lg={3}>
                    {/* single book card start  */}
                    <Book bookData={item} key={index} />
                    {/* single book card end  */}
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
