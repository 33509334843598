import React, { Component } from "react";
import {
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
  Modal,
} from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";

import Global from "../Global.json";

import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import swal from "sweetalert";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginForm: 1,
      Password: "",
      email: "",
      phone: "",
      newPassword: "",
      emailForgetPassword: "",
      emailForgetPasswordError: false,
    };
  }

  componentWillMount() {}

  render() {
    function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    return (
      <div className="loginPage">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="login_form">
                {/* img backgraound  form start  */}
                <img src="/img/page.png" alt="k" className="form_bg" />
                {/* img backgraound  form end  */}
                {/* form tabs section start  */}
                <div className="loginTabs">
                  <Row className="no-margin">
                    <Col xs={6} className="no-padd">
                      <span
                        className={
                          this.state.loginForm == 1 ? "loginSelected" : ""
                        }
                        onClick={() => {
                          window.location.href = "/login";
                        }}
                      >
                        تسجيل دخول
                      </span>
                    </Col>
                    <Col xs={6} className="no-padd">
                      <span
                        className={
                          this.state.loginForm == 2 ? "loginSelected" : ""
                        }
                        onClick={() => {
                          window.location.href = "/register";
                        }}
                      >
                        تسجيل
                      </span>
                    </Col>
                  </Row>
                </div>
                {/* form tabs section end  */}

                {/* form  section start  */}
                <div className="formInputs">
                  <input
                    type="text"
                    className={this.state.emailEmpty ? "border_red" : ""}
                    placeholder={this.props.i18n.email}
                    onChange={(e) => {
                      this.setState(
                        {
                          email: e.target.value,
                          emailEmpty: false,
                        },
                        () => {
                          this.emailValidate(this.state.email);
                        }
                      );
                    }}
                    value={this.state.email.trim()}
                  />
                  {this.state.emailError && this.state.email != "" ? (
                    <span className="note-pass">الايميل غير صحيح</span>
                  ) : null}
                  <InputGroup className="mb-3">
                    <FormControl
                      type={this.state.showPassNew ? "text" : "password"}
                      aria-describedby="basic-addon1"
                      placeholder={this.props.i18n.password}
                      onChange={(e) => {
                        this.setState({
                          Password: e.target.value,
                          PasswordEmpty: false,
                        });
                      }}
                      value={this.state.Password.trim()}
                    />
                    <InputGroup.Append>
                      {this.state.showPassNew ? (
                        // <img
                        //   src="/img/viewer.svg"
                        //   className="pass-view"
                        //   onClick={() =>
                        //     this.setState({
                        //       showPassNew: !this.state.showPassNew,
                        //     })
                        //   }
                        // />
                        <img
                          src="/img/Group 731.png"
                          className="reg-inp-img"
                          onClick={() =>
                            this.setState({
                              showPassNew: !this.state.showPassNew,
                            })
                          }
                        />
                      ) : (
                        <img
                          src="/img/eye_close@noon.png"
                          className="pass-view"
                          onClick={() =>
                            this.setState({
                              showPassNew: !this.state.showPassNew,
                            })
                          }
                        />
                      )}
                    </InputGroup.Append>
                  </InputGroup>

                  <span
                    className="forgetMyPassword"
                    onClick={() => {
                      this.setState({
                        EnteryourEmail: true,
                        emailForgetPassword: "",
                        VerificationCode: "",
                        newPassword: "",
                        ConfirmNewPassword: "",
                      });
                    }}
                  >
                    نسيت كلمة المرور ؟{" "}
                  </span>
                </div>

                {/* form  section end  */}

                {/* social buttons  section start  */}
                <div className="socilaLogin_buttons">
                  <Row className="no-margin">
                    <Col xs={6} className="no-padd">
                      <div className="socilaLogin">
                        <p>تسجيل عن طريق </p>
                        <FacebookLogin
                          appId="3487964654610027"
                          autoLoad={false}
                          fields="id,name,email,picture"
                          callback={this.responseFacebook}
                          onClick={() => this.setState({ isPageLoad: true })}
                          cssclassName="facebookLgin"
                          isMobile={false}
                          icon="fa-facebook"
                          textButton={null}
                          className="facbookLogin"
                        />
                        <GoogleLogin
                          clientId="233832999807-pcv295f9mmj0k2c9addbpdi4dakdno9r.apps.googleusercontent.com"
                          buttonText={null}
                          onSuccess={this.responseGoogle}
                          onFailure={(error) => {
                            console.log(error);
                          }}
                          onClick={() => this.setState({ isPageLoad: true })}
                          cookiePolicy={"single_host_origin"}
                          className="GoogleLoginIcon"
                          // icon="fa-facebook"
                        />
                      </div>
                    </Col>
                    <Col xs={6} className="no-padd margin-top-auto">
                      {this.state.loginForm == 1 ? (
                        this.state.submitLoader ? (
                          <div className="loginLoader">
                            <div className="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="mainButton"
                            onClick={() => {
                              this.LogIn();
                            }}
                          >
                            {/* submit button start  */}
                            <div></div> {this.props.i18n.login}
                            {/* submit button end  */}
                          </div>
                        )
                      ) : (
                        <div className="mainButton">
                          {/* submit button start  */}
                          <div></div> انشاء الحساب
                          {/* submit button end  */}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
                {/* social buttons  section end  */}
              </div>
            </Col>

            {/* main login img start  */}
            <Col md={6} className="margin-top-auto d_none_under992">
              <div className="loginMainImg">
                <img src="/img/Group 1052.png" alt="l" />
              </div>
            </Col>
            {/* main login img end  */}
          </Row>
        </Container>

        {/* Enter your email modal start */}

        <Modal
          show={this.state.EnteryourEmail}
          onHide={() => {
            this.setState({
              EnteryourEmail: false,
            });
          }}
          className="enterEmailModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>استعادة كلمة المرور</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>أدخل بريدك الالكتروني</h4>
            <input
              type="text"
              placeholder={this.props.i18n.email}
              className={
                this.state.emailForgetPasswordEmpty ? "border_red" : ""
              }
              onChange={(e) => {
                this.setState(
                  {
                    emailForgetPassword: e.target.value,
                    emailForgetPasswordEmpty: false,
                  },
                  () => {
                    this.emailValidate(this.state.emailForgetPassword);
                  }
                );
              }}
              value={this.state.emailForgetPassword.trim()}
            />
            {this.state.emailForgetPasswordError &&
            this.state.emailForgetPassword != "" ? (
              <span className="note-pass">الايميل غير صحيح</span>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <div
              className="sendEmail"
              onClick={() => {
                this.sendEmail();
              }}
            >
              ارسال
            </div>
          </Modal.Footer>
        </Modal>
        {/* Enter your email modal end  */}

        {/* Enter your email modal start */}

        <Modal
          show={this.state.VerificationModal}
          onHide={() => {
            this.setState({
              VerificationModal: false,
            });
          }}
          className="enterEmailModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>استعادة كلمة المرور</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>أدخل رمز التحقق</h4>
            <input
              type="text"
              placeholder={this.props.i18n.VerificationCode}
              onChange={(e) => {
                this.setState({
                  VerificationCode: e.target.value,
                });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <div
              className="sendEmail"
              onClick={() => {
                this.MatchVerificationCode();
              }}
            >
              ارسال
            </div>
          </Modal.Footer>
        </Modal>
        {/* Enter your email modal end  */}

        {/* change password modal start */}

        <Modal
          show={this.state.forgetMyPasswordModal}
          onHide={() => {
            this.setState({
              forgetMyPasswordModal: false,
            });
          }}
          className="enterEmailModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>تغيير كلمة المرور</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.props.i18n.newPassword}</h4>
            {/*  New Password input start  */}

            <input
              type="text"
              placeholder={this.props.i18n.newPassword}
              onChange={(e) => {
                this.setState({
                  newPassword: e.target.value,
                });
              }}
              value={this.state.newPassword.trim()}
            />
            {this.state.newPassword.length < 6 &&
            this.state.newPassword != "" ? (
              <span className="note-pass">{this.props.i18n.mustBeMore6}</span>
            ) : null}
            {/*  New Password input end  */}

            <h4>{this.props.i18n.ConfirmNewPassword}</h4>

            {/* Confirm New Password input start  */}

            <input
              type="text"
              placeholder={this.props.i18n.ConfirmNewPassword}
              onChange={(e) => {
                this.setState({
                  ConfirmNewPassword: e.target.value,
                  PasswordEmpty: false,
                });
              }}
            />

            {/* Confirm New Password input end  */}
          </Modal.Body>
          <Modal.Footer>
            <div
              className="sendEmail"
              onClick={() => {
                this.isMatch();
              }}
            >
              ارسال
            </div>
          </Modal.Footer>
        </Modal>
        {/* change password modal end  */}
      </div>
    );
  }

  //  Login api  start
  LogIn() {
    if (
      this.state.email == "" ||
      this.state.email == null ||
      this.state.Password == "" ||
      this.state.Password == null ||
      this.state.emailError
    ) {
      if (this.state.email == "" || this.state.email == null) {
        this.setState({
          emailEmpty: true,
        });
      }
      if (this.state.Password == "" || this.state.Password == null) {
        this.setState({
          PasswordEmpty: true,
        });
      }
    } else {
      this.setState(
        {
          submitLoader: true,
        },
        () => {
          fetch(Global.api_link + "userLogin", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              // Token: Global.token,
              // Authorization: "Bearer " + Global.token,
            },
            body: JSON.stringify({
              email: this.state.email,
              password: this.state.Password,
              device_id: Math.random(),
            }),
          })
            .then((response) => response.json())
            .then(async (result) => {
              this.setState(
                {
                  submitLoader: false,
                },
                async () => {
                  if (result.data == false) {
                    swal({
                      title: this.props.i18n.sorry,
                      text: "البريد الالكتروني او كلمة المرور غير صحيحة",
                      icon: "warning",
                      button: {
                        text: this.props.i18n.ok,
                      },
                      // className: "addAccountSwal",
                    });
                  } else {
                    let { actions } = this.props;
                    await actions.setUser({
                      ...this.props.user,
                      UID: result.data,
                      // userName: result.data.user.name,
                      // access_token: result.data.access_token,
                    });

                    window.location.href = "/";
                  }
                }
              );
            })
            .catch((error) => {
              console.error(error);
            });
        }
      );
    }
  }
  //  Login api  end

  /**  send Email api */
  sendEmail() {
    if (
      this.state.emailForgetPassword == "" ||
      this.state.emailForgetPassword == null ||
      this.state.emailForgetPasswordError
    ) {
      if (
        this.state.emailForgetPassword == "" ||
        this.state.emailForgetPassword == null
      ) {
        this.setState({
          emailForgetPasswordEmpty: true,
        });
      }
    } else {
      fetch(Global.api_link + "ResetPassword", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.emailForgetPassword,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data == 0) {
            swal({
              title: this.props.i18n.sorry,
              text: "البريد الالكتروني غير مسجل",
              icon: "warning",
              button: {
                text: this.props.i18n.ok,
              },
              // className: "addAccountSwal",
            });
          } else {
            this.setState({
              TrueVerificationCode: result.data,
              userId: result.user_id,
              EnteryourEmail: false,
              VerificationModal: true,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  /**  send Email api End */
  /**  send Verification Code api */
  MatchVerificationCode() {
    if (this.state.VerificationCode == this.state.TrueVerificationCode) {
      this.setState({
        VerificationModal: false,
        forgetMyPasswordModal: true,
      });
    } else {
      swal({
        title: this.props.i18n.sorry,
        text: "الرمز المدخل غير صحيح",
        icon: "warning",
        button: {
          text: this.props.i18n.ok,
        },
        // className: "addAccountSwal",
      });
    }
  }
  /**  send Verification Code api End */
  // is match function start
  isMatch() {
    if (this.state.newPassword == this.state.ConfirmNewPassword) {
      this.changePassword();
    } else {
      swal({
        title: this.props.i18n.sorry,
        text: "كلمة المرور غير متطابقة",
        icon: "warning",
        button: {
          text: this.props.i18n.ok,
        },
        // className: "addAccountSwal",
      });
    }
  }

  // is match function end

  /**  change Password Page */
  changePassword() {
    fetch(Global.api_link + "EditPassword", {
      method: "POSt",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.state.userId,
        password: this.state.newPassword,
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        swal({
          title: this.props.i18n.done,
          text: "تم تغيير كلمة المرور",
          icon: "success",
          button: {
            text: this.props.i18n.ok,
          },
          // className: "addAccountSwal",
        }).then(() => {
          this.setState({
            forgetMyPasswordModal: false,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  /**  change Password Page End */

  //// email validation function
  validateEmailRegular(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  emailValidate(value) {
    if (!this.validateEmailRegular(value)) {
      if (this.state.EnteryourEmail) {
        this.setState({
          emailForgetPasswordError: true,
          // emailValidate: value + this.props.i18n.notValidEmail,
        });
      } else {
        this.setState({
          emailError: true,
          emailValidate: value + this.props.i18n.notValidEmail,
        });
      }
    } else {
      if (this.state.EnteryourEmail) {
        this.setState({
          emailForgetPasswordError: false,
          // emailValidate: this.props.i18n.insertEmail,
        });
      } else {
        this.setState({
          emailError: false,
          // emailValidate: this.props.i18n.insertEmail,
        });
      }
    }
  }
  //// end email validation function

  responseFacebook = (response) => {
    //console.log(response);
    if (response.id) {
      fetch(Global.api_link + "FacebookLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          email: response.email ? response.email : "",
          name: response.name ? response.name : "",
          image: response.image ? response.image : "",
          facebook_id: response.id ? response.id : "",
        }),
      })
        .then((response) => response.json())
        .then(async (result) => {
          let { actions } = this.props;
          await actions.setUser({
            ...this.props.user,
            UID: result.data,
            // userName: result.data.user.name,
            // access_token: result.data.access_token,
          });

          window.location.href = "/";
        })
        .catch((error) => {
          //console.error(error);
        });
    }
  };

  responseGoogle = (response) => {
    //console.error(response);
    if (response.googleId) {
      fetch(Global.api_link + "GoogleLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          email: response.profileObj.email ? response.profileObj.email : "",
          image: response.profileObj.imageUrl
            ? response.profileObj.imageUrl
            : "",
          name: response.profileObj.givenName
            ? response.profileObj.givenName +
              " " +
              response.profileObj.familyName
            : "",
          google_id: response.profileObj.googleId
            ? response.profileObj.googleId
            : "",
        }),
      })
        .then((response) => response.json())
        .then(async (result) => {
          let { actions } = this.props;
          await actions.setUser({
            ...this.props.user,
            UID: result.data,
            // userName: result.data.user.name,
            // access_token: result.data.access_token,
          });

          window.location.href = "/";
        })
        .catch((error) => {
          //console.error(error);
        });
    }
  };
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  // i18n: state.language.lang === "en" ? Global.en : Global.ar,
  i18n: Global.ar,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
