import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
/////////////////// presist
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
////// presist

import rootReducer from "../RootReducer";

/////
// const persistedReducer = persistReducer({ key: 'merwas', storage , whitelist:['users']}, rootReducer , )
const persistedReducer = persistReducer(
  { key: "NoonLibrary", storage },
  rootReducer
);
//////

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
let persistor = persistStore(store);
// export default store;

export { store, persistor };
