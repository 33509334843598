import { USER_SET } from "../actionTypes/index";
import { FIRST_TIME } from "../actionTypes/index";
import { SET_COUNTRY_ID } from "../actionTypes/index";
import { SET_CART_COUNT } from "../actionTypes/index";

const initialState = {
  user: {
    UID: 69,
    userName: "",
    userEmail: "",
    access_token: "",
    userImage: "",
    userPhone: "",
    FacebookFollowUsed: false,
    socialShareUsed: false,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SET:
      return {
        ...state,
        user: action.payload,
      };

    // user: {
    //   // ...state.user,
    //   UID: action.payload.UID,
    //   userName: action.payload.userName,
    //   userEmail: action.payload.userEmail,
    //   userPhone: action.payload.userPhone,
    //   userImage: action.payload.userImage,
    //   FacebookFollowUsed: action.payload.FacebookFollowUsed,
    // },

    default:
      return state;
  }
};
export default userReducer;
