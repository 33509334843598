import { combineReducers } from "redux";
import languageReducer from "./reducers/languageReducer";
import userReducer from "./reducers/userReducer";

const rootReducer = combineReducers({
  language: languageReducer,
  userData: userReducer,
});

export default rootReducer;
