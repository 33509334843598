import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import Global from "../Global.json";
import * as lanuageActions from "../redux/actions/language";
import * as userActions from "../redux/actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Skeleton from "react-loading-skeleton";

class PublicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publicPage: {},
      publicPageLoder: true,
    };
  }

  componentWillMount() {
    this.getPublicPage();
  }

  /**  get Public Page */
  getPublicPage() {
    fetch(Global.api_link + "GetAboutAndPrivacy", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.props.user.UID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          publicPage: result.settings[0],
          publicPageLoder: false,
        });
      })

      .catch((error) => {
        console.error(error);
      });
  }

  /**  get Public Page End */

  render() {
    return (
      <div className="publicPage">
        <Container>
          {this.state.publicPageLoder ? (
            <Skeleton height={400} />
          ) : (
            <>
              {/* <h2>{this.props.match.params.key}</h2> */}
              {/* <p> */}
              {this.props.match.params.key == "about" ? (
                <div className="aboutSection">
                  <img src={Global.image_link + this.state.publicPage.about} />
                </div>
              ) : this.props.match.params.key == "privacy_policy" ? (
                this.MyComponent(this.state.publicPage.privacy_policy)
              ) : this.props.match.params.key == "conditions" ? (
                this.MyComponent(this.state.publicPage.conditions)
              ) : (
                this.MyComponent(this.state.publicPage.property_rights)
              )}
              {/* </p> */}
            </>
          )}
        </Container>
      </div>
    );
  }

  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }

  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
  i18n: state.language.lang === "en" ? Global.en : Global.ar,
  firstTime: state.userData.firstTime,
  user: state.userData.user,
});
const ActionCreators = Object.assign({}, lanuageActions, userActions);
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicPage);
