import { LANGUAGE_SET } from "../actionTypes/index";
import { LANGUAGE_REQUEST } from "../actionTypes/index";

const initialState = {
  lang: "ar",
  isLoading: false,
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LANGUAGE_SET:
      return {
        ...state,
        lang: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default languageReducer;
